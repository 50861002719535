// react plugin used to create charts
import React from "react";
import { Scatter } from "react-chartjs-2";

const LinechartDL = React.memo((props) => {
  //console.log("DL props: ", props);

  const { state, selectedDVL, vesselName, linechartRef, solutions, voyage, selectedWP } = props;

  var labelStringY, labelStringX;
  var idY, idX;
  var jsonDataLabelY, jsonDataLabelX;
  var dataDist, dataTimeOrCo2, dataVmax;
  //var dataTime;
  var legendText, legendColor;

  const fromDVP = state.port.fromValue;
  const toDVP = state.port.toValue;
  const dateTime = state.date + "_" + state.time;
  const vessel = state.vessel;
  //const vesselName = (state.power === "70") ? vesselNamesMB[0] : ((state.power === "85") ? vesselNamesMB[1] : vesselNamesMB[2]);
  var titleText = "", vmax;
  var flag = false;

  if(vessel === "S"){
    legendText = "Time";
    legendColor = "red"
  }
  else{
    legendText = "CO\u2082";
    legendColor = "green";
  }
  
  //todo - change with read in array
  //Label and ID for y-axis
  if(selectedDVL.y === "Speed"){
    labelStringY = "Speed over ground [kts]";
    idY = "SOG";
    jsonDataLabelY = "SOG";
  }
  else if(selectedDVL.y === "Course"){
    labelStringY = "Course over ground [degN]";
    idY = "COG";
    jsonDataLabelY = "COG";
  }
  else if(selectedDVL.y === "Wave height"){
    labelStringY = "Wave height [m]";
    idY = "Hs";
    jsonDataLabelY = "Hs";
  }
  else if(selectedDVL.y === "Wind speed"){
    labelStringY = "Wind speed [kts]";
    idY = "wind_magn";
    jsonDataLabelY = "wind_magn";
  }
  else if (selectedDVL.y === "Wind direction") {
    labelStringY = "Wind direction [degN]";
    idY = "wind_dir";
    jsonDataLabelY = "wind_dir";
  }
  else {
    labelStringY = "CO\u2082 rate [t/hr]";
    idY = "CO2t";
    jsonDataLabelY = "CO2rate";
  }

  //Default option for motorboat - unintersected options
  if(vessel === "M" && (selectedDVL.y === "Wind speed" || selectedDVL.y === "Wind direction")){
    labelStringY = "CO\u2082 rate [t/hr]";
    idY = "CO2t";
    jsonDataLabelY = "CO2rate";
  }
  //Default option for sailboat - unintersected options
  if(vessel === "S" && (selectedDVL.y === "CO2 rate" || selectedDVL.y === "Wave height")){
    labelStringY = "Speed over ground [kts]";
    idY = "SOG";
    jsonDataLabelY = "SOG";
  }

  //Label and ID for x-axis
  if (selectedDVL.x === "Cum.Distance") {
    labelStringX = "Cumulative Distance [nmi]";
    idX = "CD";
    jsonDataLabelX = "cumDist";
  }
  else {
    labelStringX = "Cumulative time [hr]";
    idX = "CT";
    jsonDataLabelX = "cumTime";
  }

  if (!(fromDVP === "from") && !(toDVP === "to") && state.date &&  !(state.time === "st") && !(state.time === "ALL") && 
  solutions && solutions.solArr[0].Departure === fromDVP && solutions.solArr[0].Arrival === toDVP &&
  voyage && voyage.state.date === state.date && voyage.state.time === state.time && voyage.state.vessel === state.vessel && (voyage.state.power === state.power || voyage.state.stype === state.stype)) {  
    
    var wpDistRadius = [], wpCORadius=[];
    //var wpTimeRadius=[];
    flag=true;
    if(vessel === "S")
      titleText = fromDVP + "_" + toDVP + "_" + dateTime;
    else
      titleText = fromDVP + "_" + toDVP + "_" + dateTime + " P" + state.power;
   
    dataDist = voyage.markersDistRoute.map((wp, i, arr) => { 
      var xVal = wp.props[jsonDataLabelX];
      var yVal = wp.props[jsonDataLabelY];
      //Handling null value condition
      if(wp.props[jsonDataLabelY] === null)
        yVal = arr[i-1].props[jsonDataLabelY];
      
        //Waypoint interaction
      if(selectedWP && selectedWP.type==="Dist" && selectedWP.id === wp.props.waypoint_ID)
        wpDistRadius.push(selectedWP.radius);
      else
        wpDistRadius.push(3);

      return ({ x: xVal, y: yVal, wid: wp.props.waypoint_ID}) 
    });
    
    // dataTime = voyage.markersTimeRoute.map((wp, i, arr) => { 
    //   var xVal = wp.props[jsonDataLabelX];
    //   var yVal = wp.props[jsonDataLabelY];
    //   //Handling null value condition
    //   if(wp.props[jsonDataLabelY] === null)
    //     yVal = arr[i-1].props[jsonDataLabelY];
    //   if(selectedWP && selectedWP.type==="Time" && selectedWP.id === wp.props.waypoint_ID)
    //     wpTimeRadius.push(selectedWP.radius);
    //   else
    //     wpTimeRadius.push(3);
    //   return ({ x: xVal, y: yVal, wid: wp.props.waypoint_ID}) 
    // });

    dataTimeOrCo2 = voyage.markersTimeOrCo2Route.map((wp, i , arr) => { 
      var xVal = wp.props[jsonDataLabelX];
      var yVal = wp.props[jsonDataLabelY];
      //Handling null value condition
      if(wp.props[jsonDataLabelY] === null)
        yVal = arr[i-1].props[jsonDataLabelY];
      if(selectedWP && selectedWP.type==="CorT" && selectedWP.id === wp.props.waypoint_ID)
        wpCORadius.push(selectedWP.radius);
      else
        wpCORadius.push(3);
      return ({ x: xVal, y: yVal, wid: wp.props.waypoint_ID}) 
    }); 

    solutions.solArr.forEach((el) => {
      if (el.Departure === fromDVP && el.Arrival === toDVP && el.depDateTime === dateTime && el.Vessel === vesselName && el.Type === "dist") {
        vmax = el.Vmax;
      }
    });

    //Reference line to be shown only for SOG
    if(selectedDVL.y === "Speed")
      dataVmax = [{x:0.1, y:vmax}];
  }
 
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: 0,
    },
    tooltips: {
      enable: true,
      intersect: false,
      mode: "point",
      callbacks: {        
        label: (tooltipitems, data) => '',
        beforeFooter:(item,data) => {
          var dataReq = data.datasets[item[0].datasetIndex].data[item[0].index].x;
          var finalData = (labelStringX.split("[")[1].includes("hr"))? convertDecimal(dataReq) : dataReq.toFixed(2);
          return labelStringX.split("[")[0] + ":  " + finalData;
        },
        footer:(item,data) =>  labelStringY.split("[")[0] + ":  " + parseFloat(data.datasets[item[0].datasetIndex].data[item[0].index].y.toFixed(2)) + " " +labelStringY.split("[").pop().split("]")[0]
      }
    },
    legend: {
      display: true,
      position: "right",
      align: "start",
      labels: {
        boxWidth: 2,
        padding: 4
      },
      onClick: (e,legendItem) => {
        var index = legendItem.datasetIndex;
        var ci = linechartRef.current.chartInstance;
        var alreadyHidden = (ci.getDatasetMeta(index).hidden === null) ? false : ci.getDatasetMeta(index).hidden;
        ci.data.datasets.forEach(function(e, i) {
          var meta = ci.getDatasetMeta(i);
          if (i !== index) {
            if (!alreadyHidden) {
              meta.hidden = meta.hidden === null ? !meta.hidden : null;
            } else if (meta.hidden === null) {
              meta.hidden = true;
            }
          } else if (i === index) {
            meta.hidden = null;
          }
        });
        ci.update();
      }
    },
    title: {
      display: true,
      text: titleText,
      padding: 1,
    },
    scales: {
      xAxes: [{
        id: idX,
        scaleLabel: {
          display: true,
          labelString: labelStringX,
          padding: 0
        },
      }],
      yAxes: [{
        id: idY,
        ticks:{                    
          maxTicksLimit: 8,
        },
        scaleLabel: {
          display: true,
          labelString: labelStringY,
          padding: 0
        },
      }]
    },
  };

  const data = {
    //dynamic color and name based on selectedDVV 
    datasets: [{
      label: legendText,
      radius: wpCORadius,
      fill: false,
      steppedLine: true,
      showLine: true,
      backgroundColor: legendColor,
      borderColor: legendColor,
      data: dataTimeOrCo2
    },
    // {
    //   label: "Time",
    //   radius: wpTimeRadius,
    //   fill: false,
    //   steppedLine: true,
    //   showLine: true,
    //   backgroundColor: "red",
    //   borderColor: "red",
    //   data: dataTime
    // },
    {
      label: "Dist",
      radius: wpDistRadius,
      fill: false,
      showLine: true,
      steppedLine: true,
      backgroundColor: "blue",
      borderColor: "blue",
      data: dataDist
    },
    {
      label: "",
      hitRadius: 0,
      radius: 0,
      backgroundColor: "white",
      borderColor: "white",
      data: dataVmax
    },
    ],
  };

  const drawVs = [
    {
      beforeDraw: chart => {
        var ctx = chart.chart.ctx;
        if (chart.scales["SOG"]) {          
          var xAxis;
          if (chart.scales["CD"])
            xAxis = chart.scales["CD"];
          else
            xAxis = chart.scales["CT"];
          var yAxis = chart.scales["SOG"].getPixelForValue(vmax);
          ctx.strokeStyle = 'black';
          ctx.fillStyle = 'black';
          ctx.beginPath();
          ctx.setLineDash([3, 3]);
          ctx.moveTo(xAxis.left, yAxis);
          ctx.lineTo(xAxis.right, yAxis);
          ctx.stroke();
          ctx.font = '10px serif';
          ctx.fillText('Vs', xAxis.left + 5, yAxis - 5);
        }
        if(!flag && toDVP !== "to" && state.time!=="ALL"){
          ctx.fillStyle = 'black';
          ctx.font = '14px calibri';
          ctx.fillText('Loading...', 216, 80);
        }
      }
    }
  ];

  return (<Scatter options={options} data={data} plugins={drawVs} className="chart-canvas" ref={linechartRef} redraw/>);
});

export default LinechartDL;

const convertDecimal = (decimalTimeString) => {
  var decimalTime = parseFloat(decimalTimeString);
  decimalTime = decimalTime * 60 * 60;
  var hours = Math.floor((decimalTime / (60 * 60)));
  decimalTime = decimalTime - (hours * 60 * 60);
  var minutes = Math.floor((decimalTime / 60));
  decimalTime = decimalTime - (minutes * 60);
  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  return (hours + ":" + minutes);
}