//[0] -> Motorboat, [1] -> Sailboat 
const DKoptions = [[{id: "dk_length", jsonId: "Distance", displayName: "Length [nmi]", value: "...", info: "Length of the least-CO\u2082 route"},
                        {id: "dk_duration", jsonId: "Duration", displayName: "Duration [hr]", value: "...", info: "Duration of the least-CO\u2082 route"},
                        {id: "dk_co2t", jsonId: "CO2t", displayName: "CO\u2082 emissions [t]", value: "...", info: "Total CO\u2082 emissions"},
                        {id: "dk_dCO2", jsonId: "dCO2t", displayName: "CO\u2082 savings [%]", value: "...", info: "CO\u2082 savings compared to least-distance route"},
                        {id: "dk_dDistance", jsonId: "dDistance", displayName: "Spatial diversion [%]", value: "...", info: "Lengthening of the route compared to least-distance one"},
                        {id: "dk_dCII", jsonId: "dCII", displayName: "CII savings [%]", value: "...", info: "Carbon intensity index, relative improvement"},
                        {id: "dk_waveMagnAvg", jsonId: "waveMagnAvg", displayName: "Wave height [m]", value: "...", info: "Average significant wave height along the route"},
                        {id: "dk_waveDirAvg", jsonId: "waveDirAvg", displayName: "Wave dir [degN]", value: "...", info: "Average wave direction along the route"}, ],
                    [{id: "dk_length", jsonId: "Distance", displayName: "Length [nmi]", value: "...", info: "Length of the least-time route"},
                        {id: "dk_duration", jsonId: "Duration", displayName: "Duration [hr]", value: "...", info: "Duration of the least-time route"},
                        {id: "dk_time", jsonId: "dDuration_hr", displayName: "Time savings [hr]", value: "...", info: "Time savings with respect to least-distance route"},
                        {id: "dk_spatial", jsonId: "dDistance_nmi", displayName: "Spatial diversion [nmi]", value: "...", info: "Lengthening of the route compared to least-distance one "},
                        {id: "dk_windMagnAvg", jsonId: "windMagnAvg", displayName: "Avg. Wind magn [kts]", value: "...", info: "Average significant wind magnitude along the route"},
                        {id: "dk_windDirAvg", jsonId: "windDirAvg", displayName: "Avg. Wind dir [deg]", value: "...", info: "Average significant wind direction along the route"},
                        {id: "dk_NsuccR", jsonId: "n_success", displayName: "Success routes", value: "...", info: "Number of sailable departure date-times"},
                        {id: "dk_NtotR", jsonId: "n_routes", displayName: "Total routes", value: "...", info: "Number of departure date-times considered"}]];
const DMoptionsWP  = [[{jsonId:"CO2rate", displayName:"CO\u2082 rate: ", units: " t/hr"},
                            {jsonId:"Hs", displayName:"Wave height: ", units: " m"},
                            {jsonId:"Th_wave", displayName:"Wave direction: ", units: " degN"}],
                        [{jsonId:"COG", displayName:"COG: ", units: " degN"},
                            {jsonId:"wind_magn", displayName:"Wind magnitude: ", units: " kts"},
                            {jsonId:"wind_dir", displayName:"Wind direction: ", units: " degN"}]];
const DDoptions = [];
const DSoptions = [];
const DLoptions = [];
const DIoptions = [[{id: "bTimeCrt", jsonId: "b-time-crt", displayName: "Sea currents forecast issued on:", value: "..."},{id: "bTimeWave", jsonId: "b-time-wave", displayName: "Waves forecast issued on:", value: "..."}],
                    [{id: "bTimeCrt", jsonId: "b-time-crt", displayName: "Sea currents forecast issued on:", value: "..."},{id: "bTimeWind", jsonId: "b-time-wind", displayName: "Winds forecast issued on:", value: "..."}]];

export {DKoptions, DMoptionsWP, DDoptions, DSoptions, DLoptions, DIoptions};