import React from "react";
import {FormGroup,Input,Row,Col} from "reactstrap";
import {mapOptionsSB, mapOptionsMB, mapOptionsDVPALL, mapOptionsDVTALLAnimation, mapOptionsDVTALLBundles} from "static/sidebarOptions.js"


const DVM = React.memo((props) => {
    //console.log("DVM props: ",props);
    const {selectedDVP, selectedDVV, selectedDVT, selectedDVM, changeSelectOptionHandler } = props;
  
    var options, optionsDVTALL;
    var disableValue = false, mapDVTALLValue = true;
        
    if (selectedDVP.fromValue === "WEST" || selectedDVP.fromValue === "EAST") 
        options = mapOptionsDVPALL.map((el) => <option key={el} value={el}>{el}</option>) ;
    else if (selectedDVT === "ALL" && !(selectedDVP.fromValue === "WEST" || selectedDVP.fromValue === "EAST")) {
        //UNCOMMENT WHEN ANIMATION IS DONE
        //mapDVTALLValue = false;
        //options = mapOptionsDVTALL.map((el) => <option key={el} value={el}>{el}</option>) ;
        mapDVTALLValue = true;
        options = mapOptionsDVTALLBundles.map((el) => <option key={el+"DVTAll"} value={el+"DVTAll"}>{el}</option>) ;
        // When DVT=ALL and DVM=Bundles
        if (selectedDVM === "Animation" || selectedDVM === "Sea currentsDVTAll" || selectedDVM === "WavesDVTAll") {
            optionsDVTALL = mapOptionsDVTALLAnimation.map((el) => <option key={el+"DVTAll"} value={el+"DVTAll"}>{el}</option>) ;
        }
        // When DVT=ALL and DVM=Animation
        else {
            optionsDVTALL = mapOptionsDVTALLBundles.map((el) => <option key={el+"DVTAll"} value={el+"DVTAll"}>{el}</option>) ;
        }
    }
    else if(selectedDVV === "S")
        options = mapOptionsSB.map((el) => <option key={el} value={el}>{el}</option>);
    else    
        options = mapOptionsMB.map((el) => <option key={el} value={el}>{el}</option>);
    
    if(!selectedDVP.toValue || selectedDVP.toValue === "to" || !selectedDVT || selectedDVT === "st" || selectedDVT === "ALL")
        disableValue = true;

    return (
        <Row className="row pl-5 pt-3">
            <Col md="10" xs="9" className="mr-0 ml-0 pl-0 pr-0">
                <FormGroup>
                    <Col>
                        {<Input id="exampleFormControlSelect" type="select" value={selectedDVM} disabled={disableValue} onChange={changeSelectOptionHandler}>
                            {options}
                        </Input>}
                    </Col>
                </FormGroup>
                <FormGroup hidden={mapDVTALLValue}>
                    <Col>
                        {<Input id="exampleFormControlSelect" type="select" onChange={changeSelectOptionHandler}>
                            {optionsDVTALL}
                        </Input>}
                    </Col>
                </FormGroup>
            </Col>
        </Row>
    );

});

export default DVM;
