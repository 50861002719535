import React from "react";
import Chart from "chart.js"
// reactstrap components
import { Card, CardTitle, CardBody, Container, Row, Col } from "reactstrap";
// core components
import KeyMetricsDK from "variables/KeyMetricsDK.js";
import BDateInfoDI from "variables/BDateDI.js";
import LinechartDL from "variables/LinechartDL.js"
import DiagramDD from "variables/DiagramDD.js"
import PlotDS from "variables/PlotDS.js"
import MapDM from "variables/Map/MapDM.js"
import { getJsonRoute, getScatterSBFile } from 'static/readData';
import { CookieBanner } from '@palmabit/react-cookie-law';
import { ToastContainer, toast } from 'react-toastify';

const Dashboard = React.memo((props) => {

  //console.log("Dashboard props: ",props.selectedDVD);

  const { clearSelection, selectedDVP, selectedDVD, selectedDVT, setVesselSelected, selectedDVV, selectedDVE, setEngineSelected, selectedDVS, setSailTypeSelected, selectedDVM, setMapSelected, selectedDVL, vesselName, setVesselFolderName, solutionJson, handlePortMarkerRef, portMarkerClick, clearValue, convertBundleToRoute } = props;
  const swappedDVP = { sFromValue: selectedDVP.toValue, sToValue: selectedDVP.fromValue };

  const [state, setState] = React.useState({ port: selectedDVP, swappedPort: swappedDVP });
  const [mapState, setMapState] = React.useState({ port: selectedDVP });
  const [voyageJson, setVoyageJson] = React.useState();

  const linechartRef = React.useRef();
  const swappedDiagramRef = React.useRef();
  const diagramRef = React.useRef();
  const mapRef = React.useRef();

  //Variables to be updated on map click
  var mapWPEvent = false, mapWpId, mapBundleEventDVT = false,  mapBundleId=null;
  var mapBundleEventDVP = false;
  //Variables to be updated on diagram click
  var dateTimeDD, powerDD, sbtypeDD, diaId, diagramEvent = false;
  //Variables to highlight waypoint on linechart click
  var linechartWPEvent = false, lcWpId;

  //Map bundle click event when DVP/DVT=ALL
  const routeBundleClick = (date, time, engine, sbtype, port) => {
    //console.log("Checking function call: "+port+","+date+","+time+","+engine);
    //East-west bundles
    if (port) {
      mapBundleEventDVP = true;
      mapBundleId = { date: date, time: time, power: engine, sbtype:sbtype, port: { fromValue: port.split("_")[0], toValue: port.split("_")[1] }, swapPort: { sFromValue: port.split("_")[1], sToValue: port.split("_")[0] } };
    }
    //dvt all bundles
    else {
      mapBundleEventDVT = true;
      mapBundleId = { date: date, time: time, power: engine, sbtype:sbtype };
    }
    if (selectedDVP.fromValue === "EAST" || selectedDVP.fromValue === "WEST" || selectedDVT === "ALL")
      handleStateChange();

  };

  //Map waypoint click event when DVP/DVT!=ALL
  const routeMarkerClick = (type, wpId, radius, map) => {
    const activeLegend = legendActive(map, linechartRef.current.chartInstance);
    if(!activeLegend){
      mapWPEvent = true;
      const r = (radius === 4) ? 8 : 4;
      mapWpId = { id: wpId, type: type, radius: r };
      if (!(selectedDVP.fromValue === "WEST" || selectedDVP.fromValue === "EAST" || selectedDVT === "ALL"))
        handleStateChange();
    }
  };
  
  //Reading chart click events
  Chart.defaults.global.onClick = (event, item) => {
    if (item[0]) {
      const dataChart = item[0]._chart.config.data.datasets[item[0]._datasetIndex];
      /** Diagram click event */
      //Motorboat diagram (DD)
      if (item[0]._xScale.id === "CO2") {
        diaId = dataChart.diaId;
        dateTimeDD = dataChart.depDateTime;
        powerDD = dataChart.power;
        if (diaId === "left" & (!(selectedDVP.fromValue === "EAST" || selectedDVP.fromValue === "WEST") && selectedDVT === "ALL")) {
          diagramEvent = true;
          handleStateChange();
        }
      }
      //Sailboat scatterplot (DS)
      else if(item[0]._xScale.id === "dist"){
        //console.log(dataChart)
        diaId = dataChart.diaId;
        dateTimeDD = dataChart.depDateTime;
        sbtypeDD = dataChart.sbtype;
        if (diaId === "left" & (!(selectedDVP.fromValue === "EAST" || selectedDVP.fromValue === "WEST") && selectedDVT === "ALL")) {
          diagramEvent = true;
          handleStateChange();
        }
      }
      /** Linechart click event */
      else {
        const activeLegend = legendActive(mapRef.current.mapRef.current, item[0]._chart);
        if(!activeLegend) {
          linechartWPEvent = true;
          const waypointID = dataChart.data[item[0]._index].wid;
          const voyageType = dataChart.label === "Dist" ? "Dist" : "CorT";
          const r = (item[0]._options.radius  === 3)? 8 : 3;
          lcWpId = { id: waypointID, type: voyageType, radius: r};
          //linechartRef.current.chartInstance.update();
          //item[0]._model.radius += 3;
          if (!(selectedDVP.fromValue === "WEST" || selectedDVP.fromValue === "EAST" || selectedDVT === "ALL")) {
            handleStateChange();
          }
        }
      }
    }
  }

  const handleStateChange = () => {
    //if (selectedDVP.fromValue) {
    // //Case 1: DVP=ALL, DVT=ALL
    // if (selectedDVP.fromValue === "WEST" || selectedDVP.fromValue === "EAST") {
    //   //map click event
    //   if (mapBundleEventDVP) {
    //     //console.log("map bundle event: dvp=all");
    //     setState({ port: mapBundleId.port, swappedPort: mapBundleId.swapPort, diaId: "ALL", date: mapBundleId.date, time: mapBundleId.time, power: mapBundleId.power });
    //     setMapState({ port: selectedDVP, date: "ALL", time: "ALL", power: "ALL", selectedBundle: { date: mapBundleId.date, time: mapBundleId.time, power: mapBundleId.power, port: mapBundleId.port } });
    //     mapBundleEventDVP = false;
    //   }
    //   //default event: no click event
    //   else {
    //     //console.log("no event: dvp=all");
    //     setMapState({ port: selectedDVP, date: "ALL", time: "ALL", power: "ALL" });
    //     setState({ port: selectedDVP, swappedPort: swappedDVP, date: "ALL", time: "ALL", power: "ALL" });
    //   }
    // }
    //Case 2: DVP!=ALL, DVT=ALL
    if (!(selectedDVP.fromValue === "WEST" || selectedDVP.fromValue === "EAST") && selectedDVT === "ALL") {
      //map click event
      if (mapBundleEventDVT) {
        //console.log("map bundle event: dvt=all");
        mapBundleEventDVT = false;
        setMapState({ port: selectedDVP, vessel: selectedDVV, date: "ALL", time: "ALL", power: "ALL", stype: "ALL", selectedBundle: { date: mapBundleId.date, time: mapBundleId.time, power: mapBundleId.power, stype: mapBundleId.sbtype } });
        setState({ port: selectedDVP, swappedPort: swappedDVP, vessel: selectedDVV, diaId: "ALL", date: mapBundleId.date, time: mapBundleId.time, power: mapBundleId.power, stype: mapBundleId.sbtype });
      }
      //left diagram click event
      else if (diagramEvent) {
        //console.log("diagram event: dvt=all");
        diagramEvent = false;
        setMapState({ port: selectedDVP, vessel: selectedDVV, date: "ALL", time: "ALL", power: "ALL", stype:"ALL", selectedBundle: { date: dateTimeDD.split('_')[0], time: dateTimeDD.split('_')[1], power: powerDD, stype: sbtypeDD } });
        setState({ port: selectedDVP, swappedPort: swappedDVP, vessel: selectedDVV, diaId: "ALL", date: dateTimeDD.split('_')[0], time: dateTimeDD.split('_')[1], power: powerDD, stype: sbtypeDD });
      }
      //default event: no click event
      else {
        //console.log("no event: dvt=all");
        setMapState({ port: selectedDVP, vessel: selectedDVV, date: "ALL", time: "ALL", power: "ALL", stype:"ALL", });
        setState({ port: selectedDVP, swappedPort: swappedDVP, vessel: selectedDVV, date: "ALL", time: "ALL", power: "ALL", stype:"ALL", });
      }
    }
    //Case 3: DVP!=ALL, DVT!=ALL
    else {
      const formatDate = (selectedDVD) ? selectedDVD.split('-')[0] + selectedDVD.split('-')[1] + selectedDVD.split('-')[2] : undefined;
      const formatTime = (selectedDVT && selectedDVT !== "ALL" && selectedDVT !== "st") ? selectedDVT.split(':')[0] + selectedDVT.split(':')[1] : "st";
      //map waypoint event
      if (mapWPEvent) {
        //console.log("map wp event: nothing=all");
        mapWPEvent = false;
        setMapState({ port: selectedDVP, vessel: selectedDVV, date: formatDate, time: formatTime, power: selectedDVE.split('%')[0], stype: selectedDVS, selectedWP: mapWpId });
      }
      //linechart waypoint event
      else if (linechartWPEvent) {
        //console.log("linechart event: nothing=all");
        linechartWPEvent = false;
        setMapState({ port: selectedDVP, vessel: selectedDVV, date: formatDate, time: formatTime, power: selectedDVE.split('%')[0], stype: selectedDVS, selectedWP: lcWpId });
      }
      //default event: no click event
      else {
        //console.log("no event: nothing=all");
        setMapState({ port: selectedDVP, vessel: selectedDVV, date: formatDate, time: formatTime, power: selectedDVE.split('%')[0], stype: selectedDVS });
        setState({ port: selectedDVP, swappedPort: swappedDVP, vessel: selectedDVV, diaId: "notALL", date: formatDate, time: formatTime, power: selectedDVE.split('%')[0], stype: selectedDVS });
      }
    }
    //}
  }


  /** Handle state changes when sidebar selection changes **/
  React.useEffect(() => {
    //if (selectedDVP.fromValue)
    handleStateChange();
  }, [selectedDVP, selectedDVV, selectedDVD, selectedDVT, selectedDVE, selectedDVS]);

  /** Read route json data everytime sidebar selection changes **/
  React.useEffect(() => {

    /** Remove duplicate calls - only required for west-east pdt */
    // if ((selectedDVP.fromValue === "WEST" || selectedDVP.fromValue === "EAST")
    //   && state.port.fromValue !== "WEST" && state.port.fromValue !== "EAST" && state.port.toValue !== "to") {
    //   getSolutionsJsonFile(state.port.fromValue + "_" + state.port.toValue).then(res => {
    //     if (res && res[0] !== null && res[1] !== null)
    //       setSolutionJson({ solArr: res[0], revSolArr: res[1] })
    //     else
    //       window.location = "/unavailable";
    //   }).catch(function (error) {
    //     //console.log(error);
    //   });
    // }

    //console.log("State inside:", state)
    //const vesselName = (state.power === "70") ? vesselNamesMB[0] : ((state.power === "85") ? vesselNamesMB[1] : vesselNamesMB[2]);
    const routeName = state.port.fromValue + "_" + state.port.toValue + "/" + state.date + "_" + state.time + "/" +vesselName;
    if (((!(selectedDVP.fromValue === "WEST" || selectedDVP.fromValue === "EAST") && solutionJson)
      || (selectedDVP.fromValue === "WEST" || selectedDVP.fromValue === "EAST"))
      && selectedDVP.fromValue !== "from" && selectedDVP.toValue !== "to"
      && state.port.toValue !== "to" && state.time !== "st" && !(state.date === "ALL")
      && ((selectedDVV === "M" && state.power && state.power !== "") || (selectedDVV === "S" && state.stype && state.stype !== ""))
      && (!voyageJson || voyageJson.name !== routeName)) {
      const path = state.port.fromValue + "_" + state.port.toValue;
      var engine = (state.power !== "ALL") ? ((state.power !== "100") ? "0" + state.power : state.power) : "ALL";
      var stype = state.stype;

      getJsonRoute(path, state.vessel, state.date, state.time, engine, stype ).then(res => {
        if (res && res.length !== 0)
          setVoyageJson({
            state: state,
            timeOrCo2Route: res[0][1], markersTimeOrCo2Route: res[0][0], 
            distRoute: res[1][1], markersDistRoute: res[1][0], name: res[2][0]
            // co2Route: res[0][1], markersco2Route: res[0][0], distRoute: res[1][1],
            // markersDistRoute: res[1][0], timeRoute: res[2][1], markersTimeRoute: res[2][0], name: res[3][0]
          })
        else{
          //Sailboat - some paths might not have route data
          //setVesselSelected("M");   
          toast.error("The selected route was not possible due to weather conditions. Hence the earliest/nearest possible date/time is selected.", {
            position: toast.POSITION.TOP_RIGHT
          });
          //clearSelection();
          //alert("The selected route is not possible due to weather conditions. Please try selecting different port/time combinations or try again later.");
          //window.location = "/unavailable";
        }
      }).catch(function (error) {
        //window.location = "/unavailable";
        //console.log(error);
      });
    }
  // }, [state, selectedDVP]);
}, [state]);

  /** To set the vessel folder name on change in vessel type and sub options **/
  React.useEffect(() => {
    setVesselFolderName();
  }, [selectedDVV ,selectedDVE, selectedDVS]);

  React.useEffect(() => {
    //set default values for All->gotoroute->change vessel type case
    if(selectedDVV === "M" && (!selectedDVE || selectedDVE === "%"))
      setEngineSelected("85%");
    if(selectedDVV === "S" && (!selectedDVS || selectedDVS === ""))
      setSailTypeSelected("367");

    //set default map option when vesseltype changes when map option is on eof the unintersected option "wind/waves"
    if(selectedDVV==="M" && selectedDVM === "Winds")
      setMapSelected("Isolines");
    if(selectedDVV==="S" && selectedDVM === "Waves")
      setMapSelected("Isolines");

  },[selectedDVV])

  /** To set the scatterplot json files **/
  const [scatterSBJson, setScatterSBJson] = React.useState();
  React.useEffect(() => {
    if (selectedDVP.fromValue && selectedDVP.toValue && 
      !(selectedDVP.fromValue === "from") && !(selectedDVP.toValue === "to") &&
      selectedDVV === "S"){
        getScatterSBFile(selectedDVP.fromValue+"_"+selectedDVP.toValue).then(res => {
        if(res && res[0]!==null && res[1]!==null) 
        setScatterSBJson({scaArr: res[0], revScaArr:res[1]})
        else
          alert("Failed to retrieve plot data. Please try selecting different ports or try again later.");
          //window.location = "/unavailable";
      }).catch(function (error) {
         // console.log(error);
      });
    }
  },[selectedDVP, selectedDVV]);

  // React.useEffect(() => {
  //   ReactGA.pageview(window.location.pathname + window.location.search);
  //   console.log("sending");
  // }, []);

  return (
    <>
      <KeyMetricsDK port={state.port} date={state.date} time={state.time} engineLoad={state.power} vesselName={vesselName} solutions={solutionJson} />
      <Container className="mt--6" fluid>
        <Row>
          {/* Map */}
          <Col xl="6" id="map1">
            <Card className="border-0" style={{ height: '600px' }} id="map-div">
              <CardTitle className="pt-1 pr-2 m-0 text-center">
                {expandDiv("map-div")}
                <span className="h4 font-weight-bold m-0 p-0">{getMapTitle(selectedDVP, selectedDVT, selectedDVM, state)}</span>
              </CardTitle>
              <CardBody className="p-1 m-0">
                <MapDM ref={mapRef} state={mapState} mapOption={selectedDVM} vesselName={vesselName} voyage={voyageJson} portMarkerRef={handlePortMarkerRef} portMarkerClick={portMarkerClick} routeMarkerClick={routeMarkerClick} routeBundleClick={routeBundleClick} convertBundleToRoute={convertBundleToRoute} clearValue={clearValue}/>
              </CardBody>
            </Card>
          </Col>
          <Col xl="6">
            {/* Diagram */}
            <Row className="align-items-center">
              <Col className="mr-xl-0 pr-xl-0" xl="6">
                <Card className="mb-1 shadow-none" style={{ height: "370px" }} id="diagram1-div">
                  <CardTitle className="pt-1 pr-2 p-0 m-0">
                    {expandDiv("diagram1-div")}
                  </CardTitle>
                  <CardBody className="p-2">
                    {selectedDVV === "S"
                      ? <PlotDS port={state.port} date={state.date} time={state.time} stype={state.stype} vesselName={vesselName} diaId={state.diaId} diagramRef={diagramRef} scaData={scatterSBJson}/>
                      : <DiagramDD port={state.port} date={state.date} time={state.time} engineLoad={state.power} diaId={state.diaId} diagramRef={diagramRef} solutions={solutionJson} /> 
                    }
                  </CardBody>
                </Card>
              </Col>
              <Col className="ml-xl-0 pl-xl-0" xl="6">
                <Card className="mb-1 shadow-none" style={{ height: "370px" }} id="diagram2-div">
                  <CardTitle className="pt-1 pr-2 p-0 m-0">
                    {expandDiv("diagram2-div")}
                  </CardTitle>
                  <CardBody className="p-2">
                    {selectedDVV === "S"
                      ? <PlotDS port={state.swappedPort} date={state.date} time={state.time} stype={state.stype} vesselName={vesselName} diaId={state.diaId} diagramRef={swappedDiagramRef} scaData={scatterSBJson}/>
                      : <DiagramDD port={state.swappedPort} date={state.date} time={state.time} engineLoad={state.power} diaId={state.diaId} diagramRef={swappedDiagramRef} solutions={solutionJson} />
                    }
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {/* Linechart */}
            <Row>
              <div className="col">
                <Card className="mt-0" style={{ height: "225px" }} id="linechart-div">
                  <CardTitle className="pt-1 pr-2 p-0 m-0">
                    {expandDiv("linechart-div")}
                  </CardTitle>
                  <CardBody className="p-2">
                    <LinechartDL state={state} selectedDVL={selectedDVL} vesselName={vesselName} solutions={solutionJson} voyage={voyageJson} selectedWP={mapState.selectedWP} linechartRef={linechartRef}/>
                  </CardBody>
                </Card>
              </div>
            </Row>
          </Col>
        </Row>
      </Container>
      <BDateInfoDI vessel={state.vessel}/>
      <ToastContainer/>
      <div>
        <CookieBanner
          message="This website uses cookies to improve your experience. We will assume you are ok with this, but you can opt-out if you wish."
          wholeDomain={true}
          onAccept = {() => {}}
          showPreferencesOption = {false}
          showStatisticsOption = {false}
          showMarketingOption = {false}
          // onAcceptPreferences = {() => {}}
          // onAcceptStatistics = {() => {}}
          // onAcceptMarketing = {() => {}}
        />
      </div>
    </>
  );
});

//expand div feature
const fullScreen = (div) => {
  let elem = document.getElementById(div);
  if (!document.fullscreenElement && !document.mozFullScreenElement &&
    !document.webkitFullscreenElement && !document.msFullscreenElement && !window.navigator.standalone) {
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Chrome, Safari & Opera */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE/Edge */
      elem.msRequestFullscreen();
    }
  }
  else {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.cancelFullScreen) {
      document.cancelFullScreen();
    }
  }
}
const expandDiv = (div) => {

  return (
    <img
      alt="..."
      src={require("assets/img/icons/expand.png").default}
      style={{ width: '15px', height: '15px' }}
      type="button"
      align="right"
      onClick={() => fullScreen(div)}
    />
  )
};

const getMapTitle = (selectedDVP, selectedDVT, selectedDVM, state) => {
  if (selectedDVP.fromValue && selectedDVP.toValue !== "to" && selectedDVT !== "st") {
    if (selectedDVP.fromValue === "WEST")
    {
      if(selectedDVM === "Least-time")
        return "Eastbound least-time bundles";
      else
        return "Eastbound least-CO\u2082 bundles";
    }
    else if (selectedDVP.fromValue === "EAST"){
      if(selectedDVM === "Least-time")
        return  "Westbound least-time bundles";
      else
        return  "Westbound least-CO\u2082 bundles";
    }
    else if (selectedDVT === "ALL" && (selectedDVP.fromValue !== "WEST" || selectedDVP.fromValue !== "EAST"))
      return selectedDVP.fromValue + "_" + selectedDVP.toValue;
    else if (state.port.toValue !== "to" && state.time !== "st" && state.vessel === "M")
      return state.port.fromValue + "_" + state.port.toValue + "_" + state.date + "_" + state.time + " P" + state.power;
    else if (state.port.toValue !== "to" && state.time !== "st" && state.vessel === "S")
      return state.port.fromValue + "_" + state.port.toValue + "_" + state.date + "_" + state.time;
    else
      return "";
  }
  else
    return "";
}

const legendActive = (map, chart) => {
  var hidden = false;
  var arr = [];
  for(var i in map._layers){
    if(map._layers[i].options.pathOptions)
      arr.push(map._layers[i].options.pathOptions.color);
  }
  if(chart.getDatasetMeta(0).hidden || chart.getDatasetMeta(1).hidden)
    hidden = true;
  if(!((arr.includes("blue") && arr.includes("green")) || (arr.includes("blue") && arr.includes("red"))))
    hidden= true;
  return hidden;
}

export default Dashboard;