import React from "react";
import { Card, CardTitle, CardBody, Col, Button, Row, CardHeader, CardText, ListGroup, ListGroupItem } from "reactstrap";
function Feedback() {
  return (
    <>
      <div>
        <h1 className="text-white text-center text-lg-left display-3 header header-dark bg-info pb-6 pl-lg-8">
          FEEDBACK
        </h1>
        <Row className="mt--5 pl-lg-8 pr-lg-8">
          <Col lg="6" className="pb-1">
          <Card>
          <CardBody className="text-justify">
          <div class="contact-us-img">
            <img
                alt="CMCC"
                width="70%"
                height="70%"
                src= {require("assets/img/theme/contactUs.jpg").default}
            />
          </div>
          </CardBody>
          </Card>
          </Col>
          <Col lg="6" className="pb-1">
            <Card>
              <CardBody className="text-justify">
              <CardTitle className="mb-3" tag="h3">
                Your opinion is important to us
              </CardTitle>
              <CardText className="mb-4">
                  Dear User of FRAME-VISIR, <br/>
                  We value your feedback about this web application
                  and would appreciate your suggestions and recommendations to improve it. 
                  Please click on the button below to let us know your opinion.
                  {/* <a target="_blank" href="https://zenodo.org/record/6974274" rel="noreferrer">Here</a> is how we dealt with the first group of feedback forms. <br/><br/> */}
                  <br/><br/>
                  Thank you so much, <br/>
                  <i>The FRAME-VISIR development team</i>
              </CardText>
              <Button 
                color="primary"
                href="https://forms.gle/3WB7L7tNhkg8yveQ8"
                target="_blank"
              >
                Go 
                </Button>
            </CardBody>
          </Card>
          <Card>
              <CardHeader>
                <h5 className="h3 mb-0">Follow us</h5>
              </CardHeader>

              <CardBody>
                <ListGroup className="list my--3" flush>
                  <ListGroupItem className="px-0">
                    <Row className="align-items-center">
                    <Col className="col-auto">
                        <a
                          className="avatar rounded-circle social-button"
                          href="https://twitter.com/VISIRnavi"
                          target="_blank"
                          rel="noreferrer"
                        >
                            <span class="btn-inner--icon"><i class="fab fa-twitter fa-2x"></i></span>
                        </a>
                      </Col>
                      <Col className="col-auto">
                        <a
                          className="avatar rounded-circle social-button"
                          href="https://www.linkedin.com/groups/12226084/"
                          target="_blank"
                          rel="noreferrer"
                        >
                            <span class="btn-inner--icon"><i class="fab fa-linkedin-in fa-2x"></i></span>
                        </a>
                      </Col>
                      <Col className="col-auto">
                        <a
                          className="avatar rounded-circle social-button"
                          href="https://www.researchgate.net/project/FRAME-savinG-fUel-and-emissions-from-mariTime-Transport-in-the-Adriatic-region"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span class="btn-inner--icon"><i class="fab fa-researchgate fa-2x"></i></span>
                        </a>
                      </Col>
                    </Row>
                  </ListGroupItem>
                </ListGroup>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Feedback;