import React from 'react';
import { Polyline, Popup } from "react-leaflet";
import coords from "static/ports";
import {sailboatOptions} from "static/sidebarOptions.js";

class GuttaBundle extends React.Component {
  render() {
    let markersCo2orTRoute = [];
    let polylineCo2orTRoute = [];
    this.props.bundle.forEach(element => {
      markersCo2orTRoute.push(element[0]);
      polylineCo2orTRoute.push(element[1])
    });

    let bundleColor = 'green';
    if (this.props.vessel === "S") bundleColor = 'red';

    const getBundleDate = (index) => {
      return this.props.bundle[index][0][0].props.ISO_date.split("T")[0].replace(/-/g, '');
    }
    const getBundleTime = (index) => {
      return this.props.bundle[index][0][0].props.ISO_date.split("T")[1].substring(0, 5).replace(':', '');
    }
    const getBundlePort = (index) => {
      var fromPort = this.props.bundle[index][2].split("_")[0];
      var toPort = this.props.bundle[index][2].split("_")[1];
      coords.forEach(el => {
        if (el.code === fromPort)
          fromPort = el.name;
        else if (el.code === toPort)
          toPort = el.name;
      });
      return fromPort + "_" + toPort;
    }

    const isBundleClicked = (index) => {
       //East-west bundles
      if (this.props.bundle[index].length === 3) {
        if (this.props.selectedBundle &&
          this.props.selectedBundle.port.fromValue + "_" + this.props.selectedBundle.port.toValue === this.props.bundle[index][2] &&
          this.props.selectedBundle.date === getBundleDate(index) &&
          this.props.selectedBundle.time === getBundleTime(index) &&
          ((this.props.bundle[index][0][0].props.engine !== "" && this.props.selectedBundle.power === this.props.bundle[index][0][0].props.engine) ||
          (this.props.bundle[index][0][0].props.sailboat !== "" && (this.props.selectedBundle.stype).toString() === this.props.bundle[index][0][0].props.sailboat))) {
          return true;
        }
        else
          return false;
      }
      //DVT All bundles
      else {
        if (this.props.selectedBundle &&
          this.props.selectedBundle.date === getBundleDate(index) &&
          this.props.selectedBundle.time === getBundleTime(index) &&
          ((this.props.bundle[index][0][0].props.engine !== "" && this.props.selectedBundle.power === this.props.bundle[index][0][0].props.engine) ||
          (this.props.bundle[index][0][0].props.sailboat !== "" && this.props.selectedBundle.stype.toString() === this.props.bundle[index][0][0].props.sailboat))) {
          return true;
        }
        else
          return false;
      }
    }

    //let clickledBundleIndex = 0;
    const callingConvertBundle2RouteFunction = () => {
      this.props.convertBundleToRoute(this.props.selectedBundle.port, this.props.selectedBundle.date, this.props.selectedBundle.time, this.props.selectedBundle.power, this.props.selectedBundle.stype);
    }

    return ([polylineCo2orTRoute.map((singlePolyline, index) => [
      /** according to the web service, the last one route is the "dist route" (in case of product f) */
      //isBundleClicked(index) ? clickledBundleIndex = index : "",
      <Polyline
        pathOptions={{ color: (this.props.selectedProd === "f" && polylineCo2orTRoute.length - 1 === index) ? 'blue' : bundleColor }}
        positions={singlePolyline}
        // 3 - default bundle weight, 10 - weight of selected bundle, 1 - weight of unselected bundles when one bundle is selected
        // second condition signifies distance route
        weight={(isBundleClicked(index) && !(this.props.selectedProd === "f" && polylineCo2orTRoute.length - 1 === index) ?  10 : (this.props.selectedBundle ? 1 : 3))}
        eventHandlers={{
          click: (e) => {
            //East - west bundles
            if (this.props.bundle[index].length === 3)
              this.props.routeBundleClick(getBundleDate(index), getBundleTime(index), this.props.bundle[index][0][0].props.engine, this.props.bundle[index][0][0].props.sailboat, this.props.bundle[index][2])
            //dvt all bundles
            else
              this.props.routeBundleClick(getBundleDate(index), getBundleTime(index), this.props.bundle[index][0][0].props.engine, this.props.bundle[index][0][0].props.sailboat);
          },
          mouseover: (e) => {
            if (e.target.options.pathOptions.color && e.target.options.pathOptions.color === "blue")
              e.originalEvent.target.style.pointerEvents = 'none';
          },
          add: (e) => {
            if (e.target.options.weight === 10) {
              e.target.openPopup();
            }
          }
        }}
      >
        <Popup autoClose={false}>
          {(this.props.bundle[index].length === 3) ? <> Departure port:  {getBundlePort(index).split("_")[0]} <br />Arrival port:  {getBundlePort(index).split("_")[1]} <br /> </> : ""}
          Start date: {getBundleDate(index).substring(0, 4) + "-" + getBundleDate(index).substring(4, 6) + "-" + getBundleDate(index).substring(6)}<br />
          Start time: {getBundleTime(index).substring(0, 2) + ":" + getBundleTime(index).substring(2)} UTC<br />
          {(this.props.vessel === "S") ? <> Sailboat type: {getSbNameFromSbValue(this.props.bundle[index][0][0].props.sailboat)}<br /> </> : <> Engine load: {this.props.bundle[index][0][0].props.engine}%<br /> </> }
          <div style={{overflow: "auto"}}>
            <button class="change-product" onClick={callingConvertBundle2RouteFunction}>Go to route</button>
          </div>
        </Popup>
      </Polyline>

    ]),

      /* Removed routemarkers from bundles */
      //    markersCo2orTRoute.map((singleRoute, index) => [
      //    (index === 0) ? routeColor = 'blue' : routeColor = 'green',
      //     <RouteMarkers 
      //     pathOptions={{
      //       color: routeColor,
      //       opacity: 1.0, 
      //       fillOpacity: 0.0}} 
      //     icon={customMarker}
      //     coords={singleRoute} />
      //   ])
    ]
    )
  }
}

export default GuttaBundle;

function getSbNameFromSbValue(value){
  return (value === "24") ? sailboatOptions[1].name : ((value === "60") ? sailboatOptions[2].name : sailboatOptions[0].name);
}

