import { FormGroup, Input, Row, Col } from "reactstrap";
import React from "react";

import { portOptions, toOptions } from "static/sidebarOptions.js"

const DVP = React.memo((props) => {
    //console.log("DVP props: ",props);
    const { selectedDVP, setPortSelected, changeSelectOptionHandler, swapSelect } = props;

    let DVPFromOptions = portOptions.map((el) => <option key={el.split("_")[1]} value={el.split("_")[1]}>{el.split("_")[0]}</option>);

    var toPort, DVPToOptions;
    for (var i = 0; i < toOptions.length; i++) {
        if (selectedDVP.fromValue === toOptions[i].from.split("_")[1])
            toPort = toOptions[i].to;
    }

    if (toPort)
        DVPToOptions = toPort.map((el) => <option key={el.split("_")[1]} value={el.split("_")[1]}>{el.split("_")[0]}</option>);

    // if (selectedDVP.fromValue === "WEST")
    //     DVPToOptions = <option value="EAST">EAST</option>
    // else if (selectedDVP.fromValue === "EAST")
    //     DVPToOptions = <option value="WEST">WEST</option>

    //Set to port automatically based on from selection
    React.useEffect(() => {
        //to fix clear button issue
        if(!selectedDVP.fromValue)
            setPortSelected({ fromValue: "from", toValue: "to" });
        //setting DVP=ALL case
        // if (selectedDVP.fromValue === "WEST")
        //     setPortSelected({ fromValue: "WEST", toValue: "EAST" });
        // else if (selectedDVP.fromValue === "EAST")
        //     setPortSelected({ fromValue: "EAST", toValue: "WEST" });
        //Select to port by default when one option is available
        if (toPort && toPort.length === 1)
            setPortSelected({ fromValue: selectedDVP.fromValue, toValue: toPort[0].split("_")[1] });

    }, [selectedDVP.fromValue, toPort]);

    return (
        <Row className="row pl-5 pt-3">
            <Col md="9" xs="9" className="mr-0 ml-0 pl-0 pr-0">
                <FormGroup>
                    <Col>
                        <Input id="exampleFormControlSelect1" type="select" name="fromValue" value={selectedDVP.fromValue} onChange={changeSelectOptionHandler}>
                            <option hidden value="from">FROM</option>
                            {DVPFromOptions}
                            {/* <option value="WEST">WEST</option>
                            <option value="EAST">EAST</option> */}
                        </Input>
                    </Col>
                </FormGroup>
                <FormGroup>
                    <Col>
                        <Input id="exampleFormControlSelect2" type="select" name="toValue" value={selectedDVP.toValue} onChange={swapSelect("toValue")} >
                            <option hidden value="to"> TO </option>
                            {DVPToOptions}
                        </Input>
                    </Col>
                </FormGroup>
            </Col>
            <Col md="1" xs="1" className="mr-0 ml-0 pl-0 pr-0 pt-5">
                <img
                    alt="..."
                    src={require("assets/img/icons/swap.png").default}
                    style={{ cursor:'pointer', width: '20px', height: '20px', transform: 'translateZ(0)'  }}
                    //type="button"
                    onClick={() => {
                        if ((selectedDVP.fromValue && !(selectedDVP.toValue === "to"))) {
                            swapSelect("fromValue")({ target: { value: selectedDVP.toValue } });
                            swapSelect("toValue")({ target: { value: selectedDVP.fromValue } });
                        }
                        /*else if(selectedDVP.fromValue === "WEST"){
                            swapSelect("fromValue")({ target: { value: "EAST" } });
                            swapSelect("toValue")({ target: { value: "WEST" } });
                        }
                        else if(selectedDVP.fromValue === "EAST"){
                            swapSelect("fromValue")({ target: { value: "WEST" } });
                            swapSelect("toValue")({ target: { value: "EAST" } });
                        }*/
                    }}
                />
            </Col>
        </Row>
    );
});

export default DVP;
