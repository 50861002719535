import React from 'react';

class Waypoint extends React.Component {
    constructor(props){
      super();
      this.state = {
        components: [
          {CO2rate: this.props.CO2rate},
          {COG: this.props.COG},
          {HDG: this.props.HDG},
          {Hs: this.props.Hs},
          {ISO_date: this.props.ISO_date},
          {SOG: this.props.SOG},
          {Th_wave: this.props.Th_wave},
          {cumCO2: this.props.cumCO2},
          {cumDist: this.props.cumDist},
          {cumTime: this.props.cumTime},
          {lat: this.props.lat },
          {lon: this.props.lon},
          {node_ID: this.props.node_ID},
          {w_cross: this.props.w_cross},
          {w_parallel: this.props.w_parallel},
          {wind_dir: this.props.wind_dir},
          {wind_magn: this.props.wind_magn},
          {waypoint_ID: this.props.waypoint_ID},
          {engine: this.props.engine},
          {sailboat: this.props.sailboat},
          ]
        }
      }
    render() {
     return (
        <div></div>
      )
    } 
  } export default Waypoint;
  