import React from 'react';
import { Marker, Popup } from "react-leaflet";
import L from "leaflet";
import coords from "static/ports";

const customMarker = (icon, size) => L.icon({
  iconUrl: require('../../assets/img/icons/' + icon + '.png').default,
  shadowUrl: require('../../assets/img/icons/marker-shadow.png').default,
  iconAnchor: [14, 30],
  iconSize: [size, size]
});

class PortsMarkers extends React.Component {
  constructor() {
    super();
    this.state = {
      // coords: [
      //   { code: "ITAOI", name: "Ancona", lat: 43.6162, lng: 13.4974, to: ["HRDBV", "HRRJK", "HRSPU", "HRZAD"] },
      //   { code: "MEBAR", name: "Bar", lat: 42.0928, lng: 19.0798, to: ["ITBRI", "ITBDS"] },
      //   { code: "ITBRI", name: "Bari", lat: 41.1358, lng: 16.8576, to: ["MEBAR", "HRDBV", "ALDRZ", "HRSPU"] },
      //   { code: "ITBLT", name: "Barletta", lat: 41.3288, lng: 16.2907, to: ["HRZAD"] },
      //   { code: "ITBDS", name: "Brindisi", lat: 40.6456, lng: 17.9604, to: ["MEBAR", "HRDBV", "ALDRZ", "GRIGO", "GRGPA"] },
      //   { code: "HRDBV", name: "Dubrovnik", lat: 42.6524, lng: 18.0939, to: ["ITAOI", "ITBRI", "ITBDS"] },
      //   { code: "ALDRZ", name: "Durrës", lat: 41.3088, lng: 19.4547, to: ["ITBRI", "ITBDS"] },
      //   { code: "GRIGO", name: "Igoumenítsa", lat: 39.5018, lng: 20.2463, to: ["ITBDS"] },
      //   { code: "HRSPU", name: "Split", lat: 43.5201, lng: 16.4389, to: ["ITAOI", "ITBRI"] },
      //   { code: "HRZAD", name: "Zadar", lat: 44.1238, lng: 15.2174, to: ["ITAOI", "ITBLT", "ITRAN"] },
      //   { code: "HRRJK", name: "Rijeka", lat: 45.3269, lng: 14.4302, to: ["ITAOI"] },
      //   { code: "ITRAN", name: "Ravenna", lat: 44.4685, lng: 12.2572, to: ["HRZAD"] },
      //   { code: "GRGPA", name: "Patras", lat: 38.2407, lng: 21.7277, to: ["ITBDS"] }
      // ]
    };
  }

  render() {
    //const { coords } = this.state;
    var toPort = [];
    coords.forEach((el) => {
      if (el.code === this.props.path.split('_')[0])
        toPort.push(...el.to);
    });

    const getIcon = (portCode) => {
      if (!this.props.path.includes("from") && !this.props.path.includes("WEST")) {
        //FROM port
        if (this.props.path.split('_')[0] === portCode)
          return customMarker("marker-play", 30);
        //TO port
        else if (this.props.path.split('_')[1] === portCode)
          return customMarker("marker-stop", 30);
        //Active TO Ports   
        else if (toPort.includes(portCode)) {
          if (this.props.path.includes("to")) {
            return customMarker("marker", 30);
          }
          else
            return customMarker("marker-grey", 28);
        }
        //Inactive TO ports 
        else
          return customMarker("marker-grey", 28);
      }
      //Change all west ports to play/stop markers
      else if (this.props.path.split("_")[0] === "WEST") {
        if (portCode.includes("IT")) {
          return customMarker("marker-play", 30);
        }
        else {
          return customMarker("marker-stop", 30);
        }
      }
      //change all east ports to play/stop markers
      else if (this.props.path.split("_")[0] === "EAST") {
        if (portCode.includes("IT")) {
          return customMarker("marker-stop", 30);
        }
        else {
          return customMarker("marker-play", 30);
        }
      }
      //All ports initial state
      else {
        return customMarker("marker", 28);
      }
    }

    return coords.map(({ lat, lng, code, name }, index) => {
      const markerIcon = getIcon(code);
      return (<Marker
        position={[lat, lng]}
        key={index}
        ref={this.props.portMarkerRef.bind(this, index)}
        icon={markerIcon}
        eventHandlers={{
          click: (e) => {
            e.target.bindPopup(code+"<br/>"+name+"<br/>Lat: "+lat.toFixed(4) + " \u00B0N<br/>Lon: "+lng.toFixed(4) + " \u00B0E",{offset:[0, -22], closeButton:false}).openPopup();
            //console.log('port marker clicked', e);
            if (!markerIcon.options.iconUrl.includes("play") && !markerIcon.options.iconUrl.includes("stop") && !markerIcon.options.iconUrl.includes("grey")) {
              this.props.portMarkerClick(index);
            }
          },
          mouseover: (e) => {
            //if (markerIcon.options.iconUrl.includes("grey")) {
            //e.originalEvent.target.style.pointerEvents = 'none';
            //}
            e.target.bindPopup(name+" ("+code+")",{offset:[0, -22], closeButton:false}).openPopup();
          },
          mouseout: (e) => {
            e.target.closePopup();
          }
        }}
      >
        <Popup offset={[0, -22]} closeButton={false}>
          {code} <br/> {name} <br /> Lat: {lat.toFixed(4) + " \u00B0N"} <br /> Lon {lng.toFixed(4) + " \u00B0E"}
        </Popup>

      </Marker>)
    })
  }
}


export default PortsMarkers;
