// react plugin used to create charts
import React from "react"
import { Scatter } from "react-chartjs-2";
import {vesselNamesSB} from "static/sidebarOptions.js";

const PlotDS = React.memo((props) => {
  //console.log("DS props: ", props);

  const { port, date, time, stype, vesselName, diaId, diagramRef, scaData } = props;

  var fromDVP, toDVP, diag, scaArrName;
  if (port.sFromValue && port.sToValue) {
    fromDVP = port.sFromValue;
    toDVP = port.sToValue;
    diag = "right";
    scaArrName = "revScaArr";
  }
  else {
    fromDVP = port.fromValue;
    toDVP = port.toValue;
    diag = "left";
    scaArrName = "scaArr";
  }
  var DVP = fromDVP + "_" + toDVP;
  var depDateTime = date + "_" + time;

  var arr = [];
  var data = { datasets: [{data: []}] }, options = getOptions();

  if (!(DVP.includes("to")) && !(time === 'st') && !(DVP.includes("WEST")) && !(DVP.includes("EAST")) && scaData) {

    const titleText = fromDVP + "_" + toDVP;
    scaData[scaArrName].forEach((sbType) => {
        const vslName = Object.keys(sbType)[0];
        const oblique = sbType[vslName].references.Oblique;
        const vertical = sbType[vslName].references.Vertical;
        sbType[vslName].couples.forEach((el)=> {
            /** x1,y1 => empty, x2,y2 => filled **/
            arr.push({v: vslName, sb: getSbValueFromVesselName(vslName), x1: Math.abs(parseFloat(el.empty[0])), y1: Math.abs(parseFloat(el.empty[1])), x2: Math.abs(parseFloat(el.filled[0])), y2: Math.abs(parseFloat(el.filled[1])), dt: el.datetime, color: el.color, diag: diag});
        });
        //to plot reference lines
        arr.push({v: vslName, reftype:"oblique", x1: Math.abs(parseFloat(oblique.A[0])), y1: Math.abs(parseFloat(oblique.A[1])), x2: Math.abs(parseFloat(oblique.B[0])), y2: Math.abs(parseFloat(oblique.B[1])), diag: diag});
        arr.push({v: vslName, reftype:"vertical", x1: Math.abs(parseFloat(vertical.A[0])), y1: Math.abs(parseFloat(vertical.A[1])), x2: Math.abs(parseFloat(vertical.B[0])), y2: Math.abs(parseFloat(vertical.B[1])), diag: diag});
    });

    if (arr.length !== 0) {
      // To get hold of the current selection and bring it upwards
      arr.forEach(function (el, i) {
        if (el.dt === depDateTime && el.v === vesselName) {
          arr.splice(i, 1);
          arr.unshift(el);
        }
      });
      //console.log("Data arr: ",arr);

      data = { datasets: getData(arr, depDateTime, vesselName, stype, diaId) };
      options = getUpdatedOptions(arr, titleText, diaId, diag);
    }
  }
  return (<Scatter plugins={getPlugins(arr, DVP, time, diaId, diag)} options={options} data={data} className="chart-canvas" ref={diagramRef} redraw />)
});

export default PlotDS;

function getData(arr, dateTime, vesselName, stype, diaId) {

  const markersDataset = [];
  const time = dateTime.split("_")[1];

  var radius, style;
  var color, borderColor;
  arr.forEach((el, i) => {
    if(!(el.x1.isNaN || el.x2.isNaN|| el.y1.isNaN || el.y2.isNaN)){      

      //couple data
      if(el.color) {
        //Set point colors based on date-time
        //if (time === "ALL" || diaId === "ALL" || (time !== "ALL" && el.diag === "left" && el.dt === dateTime && el.v === vesselName))
        if (time === "ALL" || diaId === "ALL")
          color = getVesselColor(el.v);
        else
          color = getVesselGreyColor(el.v);
        if (!color)
          color = "gray";

        //Different styles for different vessel types
        // if(el.v === vesselNamesSB[1]){
        //   style = "triangle";}
        // else if(el.v === vesselNamesSB[2])
        //   style = "rect";
        // else
        //   style = "circle";

        //change radius for the case dvt=all interaction / sidebar selected values
        if (!(el.diag === "right") && el.dt === dateTime && (el.v).includes(stype) && (diaId !== "notALL" || time !== "ALL")) {
          style = 'triangle';
          radius = 10;
          borderColor = "black";
        }
        else {
          style = 'circle';
          radius = 3;
          borderColor = color;
        }

        //To plot two points connected  with a line
        markersDataset.push({
          //empty plot 
          yAxisID: "dur",
          label: "wind"+i,
          diaId: el.diag,
          depDateTime: el.dt,
          sbtype: el.sb,
          vessel: el.v,
          fill: false,
          pointStyle: style,
          pointBorderColor: borderColor,
          pointRadius: radius,
          pointHoverRadius: radius + 2,
          data: [{ x: el.x1, y: el.y1 }]
        },
        {
          //filled plot
          yAxisID: "dur",
          label: "windcurr"+i,
          diaId: el.diag,
          depDateTime: el.dt,
          sbtype: el.sb,
          vessel: el.v,
          pointStyle: style,
          pointBorderColor: borderColor,
          pointBackgroundColor: color,
          pointRadius: radius,
          pointHoverRadius: radius + 2,
          data: [{x: el.x2, y: el.y2}]
        },
        {
          //line connecting them
          yAxisID: "dur",
          label: "dotted",
          type: "line",
          diaId: el.diag,
          depDateTime: el.dt,
          sbtype: el.sb,
          vessel: el.v,
          fill: false,
          borderDash: [1, 1],
          borderWidth: 1,
          pointRadius: 0,
          pointHitRadius: 0,
          backgroundColor: "black",
          borderColor: "black",
          data: [{ x: el.x1, y: el.y1 }, {x: el.x2, y: el.y2}],
        })
      }
      // Pushing the reference line to the dataset array
      else{
        if(el.reftype && el.reftype === "vertical")
          color = "black";
        markersDataset.push({
          yAxisID: "dur",
          label: "dashed",
          type: "scatter",
          diaId: el.diag,
          reftype: el.reftype,
          sbtype: el.sb,
          vessel: el.v,
          fill: false,
          borderDash: [3, 3],
          borderWidth: 1,
          pointRadius: 0,
          pointHitRadius: 0,
          showLine: true,
          backgroundColor: color,
          borderColor: color,
          data: [{ x: el.x1, y: el.y1 }, {x: el.x2, y: el.y2}]
        });
      }
    }
  });
  //Pushing the colorbar
  markersDataset.push({
    yAxisID: "date",
    label: "colorbar",
    data: getColorBarDateData(arr)
  })
  return markersDataset;
};

function getOptions() {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: 0,
    },
    legend: {
      display: false
    },
    title: {
      display: true,
      padding: 1,
    },
    scales: {
      xAxes: [{
        id: "dist",
        scaleLabel: {
          display: true,
          labelString: "Distance [nmi]",
        }
      }],
      yAxes: [{
        id: "dur",
        scaleLabel: {
          display: true,
          labelString: "Duration [hr]",
        }
      }]
    }
  }
  return options;
};

function getUpdatedOptions(arr, titleText, diaId, diag) {
  var displayDateValues = true;
  // if(diaId === "notALL" && diag === "right")
  //   displayDateValues = false;
  // else
  //   displayDateValues = true;
  const options = {
    ...getOptions(),
    title: {
      display: true,
      text: titleText,
      padding: 1,
    },
    tooltips: {
      enable: true,
      intersect: false,
      mode: "point",
      titleSpacing: 4,
      titleMarginBottom:1,
      footerSpacing:4,
      footerMarginTop:1,
      callbacks: {
        beforeTitle: (item, data) => data.datasets[item[0].datasetIndex].depDateTime,
        afterTitle: (item, data) => 'Sailboat :  ' + getSbtypeFromVesselName(data.datasets[item[0].datasetIndex].vessel),
        beforeBody: (item, data) => {if(data.datasets[item[0].datasetIndex].label.includes("windcurr")) return "Wind + Current"; else return "Wind";},
        afterBody: (item, data) => '----------------------------',
        label: (tooltipitems, data) => '',
        footer: (item, data) => 'Distance :  ' + parseFloat(data.datasets[item[0].datasetIndex].data[0].x.toFixed(2)) + ' nmi',
        afterFooter: (item, data) => 'Duration :    ' + parseFloat(data.datasets[item[0].datasetIndex].data[0].y.toFixed(2)) + ' hr',
      }
    },
    onHover: (event, chartElement) => {
      if (diaId !== "notALL" && arr[0].diag === "left")
        event.target.style.cursor = (chartElement[0]) ? 'pointer' : 'default';
    },
    scales: {
      xAxes: [{
        id: "dist",
        scaleLabel: {
          display: true,
          labelString: "Distance [nmi]",
          padding: {
            top: 0,
            bottom: 25
          }
        },
        ticks: {
          min: getMinX(arr),
          max: getMaxX(arr),
        },
      }],
      yAxes: [{
        id: "dur",
        position: "left",
        scaleLabel: {
          display: true,
          labelString: "Duration [hr]",
          padding: 0
        },
        ticks: {
          min: getMinY(arr),
          max: getMaxY(arr)
        },
      },
      {
        id: "date",
        display: displayDateValues,
        position: "right",
        reverse: true,
        ticks: {
          callback: function(value, index, values) {
            return;
          },
          maxTicksLimit: 5,
          sampleSize: 5,
          padding: 20,
        },
        gridLines: {
          display: false
        },
        scaleLabel: {
          display: true,
          labelString: "",
          padding: {
            bottom: 4
          }
        }
      }
    ]
    },
  };
  return options;
}

//for legend and colorbars
function getPlugins(arr, DVP, time, diaId, diag) { 
  if (arr.length !== 0 && !(time === 'st')) {
    //const colorBars = getColorBars(arr);
    const drawColorBarAndLegend = [
      {
        beforeDraw: chart => {
          var ctx = chart.chart.ctx;
          var xAxis = chart.scales["dist"];
          var yAxis = chart.scales["date"];
          ctx.save();

          // To make the colorbar gradient for dates
          // if(!(diaId === "notALL" && diag === "right")){
          //   // To make the colorbar
          //   const yBottom = yAxis.bottom, yTop = yAxis.top;   
          //   var grd = ctx.createLinearGradient(xAxis.right + 4, yBottom, xAxis.right + 4, yTop);
          //   const stepSize = 1 / (colorBars.length - 1);
          //   colorBars.forEach((c, i) => {
          //     const pos = i * stepSize;
          //     if(c)
          //       grd.addColorStop(pos, colorBars[i]);
          //   });
          //   ctx.fillStyle = grd;
          //   ctx.fillRect(xAxis.right + 4, yTop, 10, yBottom - yTop);

          //   // To write the dates - here is the actual use
          //   const dates = getColorBarDateData(arr);
          //   ctx.font = '10px Arial';
          //   ctx.fillStyle = 'gray';
          //   const stepDate = (yBottom-yTop)/(dates.length);
          //   var currDatePos = yBottom-5;
          //   dates.forEach((date, i) =>{ 
          //     ctx.fillText(date, xAxis.right + 16, currDatePos);
          //     currDatePos -= stepDate
          //   })
          // }

          const xRight = xAxis.right, yTop = yAxis.top;
          // To make thecolor legend for vessels
          if(!(diaId === "notALL")) {
            ctx.fillStyle = 'magenta';
            ctx.fillRect(xRight+2, yTop, 5, 16);
            ctx.fillStyle = 'orange';
            ctx.fillRect(xRight+2, yTop+16+1, 5, 16);
            ctx.fillStyle = 'cyan';
            ctx.fillRect(xRight+2, yTop+16+16+1+1, 5, 16);
          }
          else {
            ctx.fillStyle = 'gray';
            ctx.fillRect(xRight+2, yTop, 5, 16);
            ctx.fillStyle = 'darkgray';
            ctx.fillRect(xRight+2, yTop+16+1, 5, 16);
            ctx.fillStyle = 'lightgray';
            ctx.fillRect(xRight+2, yTop+16+16+1+1, 5, 16);
          }
          ctx.font = '10px Arial';
          ctx.fillStyle = 'black';
          ctx.fillText('First367', xRight+2+5+2, yTop+10);
          ctx.fillText('Swan60', xRight+2+5+2, yTop+26);
          ctx.fillText('J24', xRight+2+5+2, yTop+45);

          // To make the wind legend at the bottom of the chart
          var ctx1 = chart.chart.ctx;
          ctx1.font = '11px Arial';
          ctx1.strokeStyle = 'black';
          ctx1.fillStyle = 'black';
          ctx.beginPath();
          ctx1.arc(xAxis.left, yAxis.bottom + 60, 4, 0, 2 * Math.PI, false);
          ctx1.stroke();
          ctx1.fillText(' Wind', xAxis.left + 5, yAxis.bottom + 65);
          
          ctx.font = '11px Arial';
          ctx.strokeStyle = 'lightgray';
          ctx.fillStyle = 'black';
          ctx.beginPath();
          ctx.strokeRect(xAxis.left - 20, yAxis.bottom + 50, 180, 19)
          ctx.arc(xAxis.left + 60, yAxis.bottom + 60, 4, 0, 2 * Math.PI, false);
          ctx.fillText(' Wind + Current', xAxis.left + 65, yAxis.bottom + 65);
          ctx.fill();
          ctx.restore();
        }
      }
    ];
    return drawColorBarAndLegend;
  }
  //To show Loading before loading chart
  else if((!(time === 'st') || (!(DVP.includes("to")) && arr.length === 0)) && (!(DVP.includes("WEST") || DVP.includes("EAST")))) {
    const loading = [
      {
        beforeDraw: chart => {
          var ctx = chart.chart.ctx;
          ctx.fillText('Loading...', 112, 145);
        }
      }];
    return loading;
  }
};


/** Supporting functions */

function getMinX(arr) {
  let minX = Number.MAX_VALUE;
  arr.forEach((el) => {
    //skip reference line plots
    if(!el.reftype)
      minX = Math.min(el.x1, el.x2, minX);
  });
  return parseInt(minX) - 1;
}

function getMaxX(arr) {
  let maxX = 0;
  arr.forEach((el) => {
    //skip reference line plots
    if(!el.reftype)
      maxX = Math.max(el.x1, el.x2, maxX);
  });
  return parseInt(maxX) + 1;
}

function getMinY(arr) {
  let minY = Number.MAX_VALUE;
  arr.forEach((el) => {
    //skip reference line plots
    if(!el.reftype)
      minY = Math.min(el.y1, el.y2, minY);
  });
  return parseInt(minY) - 1;
}

function getMaxY(arr) {
  let maxY = 0;
  arr.forEach((el) => {
    //skip reference line plots
    if(!el.reftype)
      maxY = Math.max(el.y1, el.y2, maxY);
  });
  return parseInt(maxY) + 1;
}

/** For colobar */

//To count same date and color data for 3 sailboat vessels only once
function getTrimmedData(arr){
  var trimmedArr = [];
  for(var i = 1; i < arr.length; i++){
    if(arr[i].reftype)
      break;
    trimmedArr.push(arr[i]);
  }
  //console.log("Trimmed array: ", trimmedArr)
  return trimmedArr;
}

//Dates to be displayed in legend
function getColorBarDateData(arr) {
  const trimmedArr = getTrimmedData(arr);
  // Get all date-time
  const datetimes = [];
  trimmedArr.forEach((el, i) => { if(el.dt) datetimes.push(el.dt) });

  // Return only dates
  const dates = datetimes.map(dateStr => {
    const year = dateStr.slice(0, 4);
    const month = dateStr.slice(4, 6);
    const day = dateStr.slice(6, 8);
    const date = new Date(year, month - 1, day);
    return date;
  });
  
  // Format unique dates
  const formattedDates = dates.map(date => {
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const day = date.getDate();
    const monthIndex = date.getMonth();
    return `${day} ${monthNames[monthIndex]}`;
  });

  // Remove duplicates
  const uniqueDates = [...new Set(formattedDates)];
  // console.log("Colorbar Date ticks: ",uniqueDates);
  return uniqueDates;
}

//To draw the colorbar in the right side of the chart
// function getColorBars(arr) {
//   const trimmedArr = getTrimmedData(arr);
//   const colorBars = trimmedArr.forEach((el, i) => { if(el.color) return el.color });
//   //console.log("Colorbar values: ",colorBars)
//   return colorBars;

//   //Same color values is repeated for all sb vessels, hence we divide by number of sb vessels
//   /** Pass colors with date values */
//   // const dates = getColorBarDateData(arr), numTicks = 5, numSBVsls = 3;
//   // const steps = Math.floor(arr.length / (numSBVsls * (numTicks - 1)));
//   // const colorBars = [];
//   // for(var i = 0, j=0; j<4; i= i+steps,j++){
//   //   if(arr[i].color){
//   //     colorBars.push({ y: dates[j], color: arr[i].color });
//   //   }
//   // }
//   // //to push the last color (the last 2 values in arr is for reference lines, hence (-3))
//   // colorBars.push({ y: dates[4], color: arr[arr.length - 3].color })
//   // //console.log("Colorbar values: ",colorBars)
//   // return colorBars;

// }

function getVesselColor(vesselName){
  let color = "gray"
  if(vesselName === vesselNamesSB[1])
    color = "cyan";
  else if(vesselName === vesselNamesSB[2])
    color = "orange";
  else
    color = "magenta";
  return color;
}

function getVesselGreyColor(vesselName){
  let color = "gray"
  if(vesselName === vesselNamesSB[1])
    color = "gray";
  else if(vesselName === vesselNamesSB[2])
    color = "darkgray";
  else
    color = "lightgray";
  return color;
}

function getSbtypeFromVesselName(str){
  return str.substring(
    str.indexOf("_") + 1, 
    str.lastIndexOf("__")
  );
}

function getSbValueFromVesselName(str){
  const regex = /\d+/; // regular expression to match one or more digits
  const match = str.match(regex); // returns an array of matches
  const intValue = match ? parseInt(match[0]) : null;
  return intValue;
}