import React from "react";
import { HashLink } from 'react-router-hash-link';
import { Card, CardImg, CardTitle, CardBody, Col, Row } from "reactstrap";
//import ReactGA from 'react-ga';
import YoutubeEmbed from "../static/YoutubeEmbed";
function Help() {

  // React.useEffect(() => {
  //   ReactGA.initialize('G-G2K5KR7XGL'); 
  //   ReactGA.pageview(window.location.pathname + window.location.search);
  // }, []);


  return (
    <>
      <div id="top">
        <h1 className="text-white text-center text-lg-left display-3 header header-dark bg-info pb-6 pl-lg-8">
          HELP
        </h1>
        <Col className="pb-1 mt--5 pl-lg-8 pr-lg-8">
          <Card>
            <CardBody className="text-center">
              <HashLink id="help-link" smooth to="#general" className="text-uppercase text-info pr-5">General functioning</HashLink>
              <HashLink id="help-link" smooth to="#advanced" className="text-uppercase text-info pl-5 pr-5">Advanced use</HashLink>
              <HashLink id="help-link" smooth to="#background" className="text-uppercase text-info pl-5">Background information</HashLink>
            </CardBody>
          </Card>
          <Card className="p-5">
            <CardTitle><h2 className="text-uppercase text-info">Video Tutorial</h2></CardTitle>
            <YoutubeEmbed width="50%" url = 'https://www.youtube.com/embed/4zKooETbac0'/>
          </Card>
          <Card id="general" name="general" className="p-5">
            <CardTitle><h2 className="text-uppercase text-info">General functioning</h2></CardTitle>
            <CardImg
              alt="..."
              src={require("assets/img/news/frame-visir.png").default}
              top
              className="pl-2 pr-2"
            />
            <CardBody className="text-justify">
              <p>
                To start, select both a departure and an arrival port from "Route", on the left menu. It is also possible to select the ports from the markers on the map. Departure and arrival port can be swapped by the symbol with vertical arrows. Departure date and time can be selected from the "Date and Time". The arrow selector also allows you to browse departure times. Once a route is selected in "Route", all departure times can be viewed through the ALL option in "Date and Time". Any route selection can be undone via the eraser button next to the map. <br /><br />

                <h3>Key metrics</h3>
                Whenever a route is selected, some key metrics of the optimal track are printed in the boxes right below the header of the page.<br/>
                "Savings" of a given quantity K are defined to be the negative relative changes on optimized routes with respect to the least-distance track (geodetic) having the same departure date and time:
                <p class="text-center"><i>K Savings = 100*(1 - (K<sub>OPT</sub>  / K<sub>GDT</sub>))</i></p>
                A list of key metrics is provided in the following table:
                <table class="help">
                  <tr>
                    <th>Motorboat</th>
                    <th>Sailboat</th>
                  </tr>
                  <tr>
                    <td>Length [nmi]</td>
                    <td>Length [nmi]</td>
                  </tr>
                  <tr>
                    <td>Duration [hr]</td>
                    <td>Duration [hr]</td>
                  </tr>
                  <tr>
                    <td>CO<sub>2</sub> emissions [t]</td>
                    <td>Time saving [hr]</td>
                  </tr>
                  <tr>
                    <td>Distance savings [%]</td>
                    <td>Route lengthening [nmi]</td>
                  </tr>
                  <tr>
                    <td>Duration savings [%]</td>
                    <td>Average wind magnitude [kts]</td>
                  </tr>
                  <tr>
                    <td>CO<sub>2</sub> savings [%]</td>
                    <td>Average wind direction [deg]</td>
                  </tr>
                  <tr>
                    <td>CII savings [%]</td>
                    <td>Success routes [#]</td>
                  </tr>
                  <tr>
                    <td>Average significant wave height [m]</td>
                    <td>Total routes [#]</td>
                  </tr>
                </table><br/>
                In case of sailboat, the “Success routes” and “Total routes” metrics appear. They follow from the fact that a sailboat route between given ports may not be found for any wind condition. The “Success routes” counts how many of the “Total routes” computed for the given end ports were found by the VISIR-2 model. <br/><br/>

                <h3>Map</h3>
                The map in this web application displays the routes on top of either isolines or environmental fields. The optimization objective can be distance (blue), time (red), or CO<sub>2</sub> emissions (green). Isolines of corresponding colors join all locations with a given expenditure of distance/time/emissions. That is, isolines answer the questions: "how far can the vessel go by sailing for a given amount of miles?/ by sailing for a given number of hours?/ by emitting a given amount of CO<sub>2</sub> ?" Units are nautical miles, hours, and tonnes, respectively. <br/>
                The environmental field rendered in the map can be the significant wave height, the surface sea currents, or the wind field.<br/><br/>

                <h3>Diagrams</h3>
                Scatterplots or diagrams are displayed in the top right part of the web application.  This panel changes according to the vessel type. However, each marker refers to a specific optimal route.<br/>
                For the <i>sailboats</i>, there are two markers for each departure time. Their coordinates are  route length and duration. The empty markers refer to the case considering just wind in the route optimization. The filled markers refer to the case considering both wind and currents. There are two reference lines: a vertical and a slant one. The vertical line represents the minimum distance between the selected ports, the slant one the time it would take to cover the distance sailing at the sailboat’s maximum speed.<br/>
                For the <i>motorboat</i>, the marker coordinates are the CO<sub>2</sub> and CII savings. The various markers are generated by varying departure date and time as well as engine load. The marker’s color represents the route relative lengthening with respect to the geodetic.<br/><br/>
                
                <h3>Linechart</h3>
                The linechart is the block with a stairstep graph located at the bottom right of the web application. If both a specific route and departure time is selected, information along the route is displayed in a linechart. Both the y and x variable can be customized (see Advanced use). <br/>
                The map, diagram, and linechart interact with each other. For instance: click on a waypoint on either map or linechart, and see the corresponding one highlighted on either line chart or map. Hovering will display a tooltip with waypoint information. The diagram interacts with the map when all departure times are selected. Then, selection of a marker would highlight the corresponding bundle component in the map, and vice versa.<br/><br/>

                <h3>Tooltip information</h3>
                <ul><li>Given departure date and time<br/>
                Each route waypoint is clickable. It displays Information on geographic location (lat/lon), and, for that location, date/time of passage, speed over ground (SOG), environmental fields (significant wave height (Hs) and sea surface currents (MCUR) for the motorboat; true wind speed (TWS) and sea surface currents (MCUR) for the sailboat), and CO<sub>2</sub> emission rate (CO<sub>2</sub> rate) where applicable.</li></ul>
                <ul><li>ALL selected in “Date and time”<br/>
                Each route bundle component is clickable. It displays corresponding departure date and time, and either sailboat type or (for the motorboat) engine load. Furthermore, a button allows switching to a visualization mode with focus on the selected route and where the background field (isoline/waves/currents) can be selected from the Map div in the left menu.</li></ul>
              </p>

              <p class="text-center pb-0 mb-0"><HashLink id="help-link" smooth to="#" className="text-uppercase text-info">go to top</HashLink></p>
            </CardBody>
          </Card>
          <Card id="advanced" name="advanced" className="p-5">
            <CardTitle className="mb-0"><h2 className="text-uppercase text-info">Advanced use</h2></CardTitle>
            <CardBody className="text-justify pt-0">
              <Row>
                <Col xl="3" className="pt-4 col-sm-3">
                  <img
                    width="170px"
                    alt="sidemenu"
                    src={require("assets/img/news/side-menu.png").default}
                  />
                </Col>
                <Col xl="9" className="col-sm-9" >
                  <p><br />
                  Besides the route selection, in the left menu you can find an additional submenu for more refined computational settings and visualization options.<br /><br />

                    <b>Date and Time</b><br />
                    By default, the earliest possible vessel departure date and time is used. This can be changed within a few days towards the future. Time is expressed in UTC (Coordinated Universal Time).<br /><br />

                    <b>Vessel Type</b> <br/>
                    User can choose between the two vessel types available : Sailboat or Motorboat. By default, user can see the Sailboat selected.<br/><br/>

                    <b>Sailboat Type</b><br/>
                    [sailboat only] By default, results relative to First_367 are displayed. However, the J24 and the Swan_60FD are additionally available sailboats.<br/><br/>

                    <b>Engine Load</b><br />
                    [flybridge only] By default, results relative to use of 85% of main engine power PME are displayed. However, the vessel’s fractional engine load can be chosen to be 70, 85, 100% of P<sub>ME</sub> <br /><br />

                    <b>Map</b><br />
                    The optimal routes can be visualized on top:
                    <ul>
                      <li>
                        Isolines (one for each optimization objective: distance/time/emissions)
                      </li>
                      <li>
                        Sea Currents: surface sea currents fields from <a target="_blank" href="https://marine.copernicus.eu/">Copernicus Marine Service</a>. Presently, we use the product  MEDSEA_ANALYSISFORECAST_PHY_006_013.
                      </li>
                      <li>
                        [sailboat] Wind: 10m wind components from the <a href="https://www.ecmwf.int/" target="_blank">European Centre for Medium-Range Weather Forecasts</a>. Presently, the high resolution 10-day forecast Atmospheric Model (Set I - HRES) is used. 
                      </li>
                      <li>
                        [flybridge] Waves: significant wave height fields from Copernicus Marine Service. Presently, we use the product MEDSEA_ANALYSISFORECAST_WAV_006_017.
                      </li>
                    </ul>

                    <b>Linechart</b><br />
                    <ul>
                      <li>The y variable can be chosen to be:<br/>
                        [sailboat] vessel speed over ground (SOG), vessel course over ground (COG), the True Wind Speed (TWS), or the True Wind Direction (TWD).<br/>
                        [flybridge] vessel speed over ground (SOG), vessel course over ground (COG), CO<sub>2</sub> emission rate, or significant wave height.
                      </li>
                      <li>The x variable can be chosen to be either cumulative time or distance from departure.</li>
                    </ul>

                    <b>Export</b><br />
                    Upon selection of a specific route, it can be downloaded in either JSON or XML format.<br/>
                    <br /><br />
                  </p>
                </Col>
              </Row>
              <p class="text-center pb-0 mb-0"><HashLink id="help-link" smooth to="#" className="text-uppercase text-info">go to top</HashLink></p>
            </CardBody>
          </Card>
          <Card id="background" name="background" className="p-5">
            <CardTitle className="mb-0"><h2 className="text-uppercase text-info">Background information</h2></CardTitle>
            <CardBody className="text-justify pt-0">
              <p className="pr-4">  
              <h3>Environmental fields </h3>
              <b>Temporal resolution</b>
                <ul><li>The temporal resolution of the selected Copernicus Marine Service wave and current products is hourly for the first 5 days since the analysis. Later on, it is hourly for waves and daily for currents.</li>
                  <li>The temporal resolution of the ECMWF wind product is hourly for the first 3 days, 3-hourly for the following 4 days and 6-hourly for the final 4 days. For routes extending beyond 5 days after analysis, the daily currents fields are linearly interpolated in time. </li>
                </ul>
              <b>Spatial resolution</b>
              <ul>
                <li>The spatial resolution of selected Copernicus Marine Service wave and current products is 1/24 degree (or 2.5 nautical miles in meridional direction).</li>
                <li>Wind products provided by ECMWF have a spatial resolution of 1/10 degree (~10.5 nautical miles in meridional direction).</li>
                <li>The target grid of the graph used by VISIR has a resolution of 1/12 degree and a connectivity up to the 4th order. This implies for the routes a spatial resolution of about 5 miles and directional resolution of 14 degree.</li>
              </ul>
              <h3>Vessels</h3>
              The vessels’ main particulars are specified in the table below. 
              <table class="help">
                  <tr>
                    <th>Vessel</th>
                    <th>Length overall [m]</th>
                    <th>Displacement [t]</th>
                    <th>Main engine power [kW]</th>
                    <th>Main Sail Area [m^2]</th>
                  </tr>
                  <tr>
                    <td>J24</td>
                    <td>7.32</td>
                    <td>1566</td>
                    <td>-</td>
                    <td>12.6</td>
                  </tr>
                  <tr>
                    <td>First367</td>
                    <td>10.68</td>
                    <td>15591766</td>
                    <td>-</td>
                    <td>38</td>
                  </tr>
                  <tr>
                    <td>Swan60FD</td>
                    <td>18.86</td>
                    <td>19067</td>
                    <td>-</td>
                    <td>25.9</td>
                  </tr>
                  <tr>
                    <td>Flybridge</td>
                    <td>20</td>
                    <td>39500</td>
                    <td>374 (x2)</td>
                    <td>-</td>
                  </tr>
                </table><br/>
                Their performance is described by the plots reported in the following.
                <Row>
                  <Col xl="6">
                    <img
                      alt="ferry"
                      width="400"
                      src={require("assets/img/help/Flybridge.png").default} />
                  </Col>
                  <Col xl="6">
                    <img
                      alt="ferry"
                      width="500"
                      src={require("assets/img/help/Sailboats.png").default} />
                  </Col>
                </Row>
              </p>
               
              <p class="text-center pb-0 mb-0"><HashLink id="help-link" smooth to="#" className="text-uppercase text-info">go to top</HashLink></p>
            </CardBody>
          </Card>
        </Col>
      </div>
    </>
  );
}

// function scroll(divId) {
//   if (document.getElementById(divId) !== null) {
//     let offsetTop = document.getElementById(divId).offsetTop;
//     window.scrollTo({
//       top: offsetTop - 10,
//       behavior: "smooth"
//     });
//   }
// }

export default Help;
