
import React from "react";
// react library for routing
//import { useLocation } from "react-router-dom";
// core components
import Header from "components/Header.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import Dashboard from "pages/Dashboard";
import {getSolutionsJsonFile} from 'static/readData';
import {vesselNamesMB, vesselNamesSB} from "static/sidebarOptions.js";


function AdminLayout() {

  //const [state, setState] = React.useState({});

  /*To manage the state of DVP*/
  //To be used as portSelected.fromValue and portSelected.toValue
  const [portSelected, setPortSelected] = React.useState({});
  const changePortSelectOptionHandler = (event) => {
    setPortSelected({fromValue: event.target.value, toValue: "to"});
  };
  const swapPortSelect = key => event => {
    setPortSelected(prevSelected => ({
      ...prevSelected,
      [key]: event.target.value
    }));
  };
  /*To manage the state of DVD and DVT*/
  const [dateSelected, setDateSelected] = React.useState();
  /*const changeDateSelectOptionHandler = (event) => {
    setDateSelected(event.target.value);
    changeTimeSelectOptionHandler("st");
  };*/
  const [timeSelected, setTimeSelected] = React.useState();
  /*const changeTimeSelectOptionHandler = (event) => {
    setTimeSelected(event.target.value);   
  };*/
   /* To manage the state of DVV */
  const [vesselSelected, setVesselSelected] = React.useState("S");
  const changeVesselSelectOptionHandler = event => {
    setVesselSelected(event.target.value);
  };
  /* To manage the state of DVM */
  //To be used as mapSelected.map and mapSelected.bundles
  const [mapSelected, setMapSelected] = React.useState();
  const changeMapSelectOptionHandler = event => {
    setMapSelected(event.target.value);
  };
  /* Implementing the following logic to update the state: 
  When DVP=ALL => DVT=ALL and DVM=Least-CO2
  When DVT=ALL => DVM=Animation, otherwise => DVM=Isolines
  React.useLayoutEffect(() => {
    if(portSelected.fromValue === "ALL" || portSelected.toValue === "ALL"){
      setTimeSelected("ALL");
      setMapSelected("Least-CO2");
    }
    else if(timeSelected === "ALL" && !(portSelected.fromValue === "ALL" || portSelected.toValue === "ALL"))
      setMapSelected("Animation");  
    else
      setMapSelected("Isolines");
  }, [portSelected, timeSelected]);*/
  /*To manage the state of DVE*/
  const [sailTypeSelected, setSailTypeSelected] = React.useState("367"); 
  const changeSailTypeSelectOptionHandler = (event) => {
    setSailTypeSelected(event.target.value);
  };
  /*To manage the state of DVE*/
  const [engineSelected, setEngineSelected] = React.useState("85%"); 
  const changeEngineSelectOptionHandler = (event) => {
    setEngineSelected(event.target.value);
  };
  /*To manage the state of DVL. 
  To be used as "chartSelected.x" and "chartSelected.y"*/
  const [chartSelected, setChartSelected] = React.useState({ y: "CO2 rate", x: "Cum.Time" });
  const changeChartSelectOptionHandler = (event) => {
    setChartSelected({
      ...chartSelected,
      [event.target.name]: event.target.value
    });
  };
  /*To manage the state of DE*/
  const [exportSelected, setExportSelected] = React.useState("sf");
  const changeExportSelectOptionHandler = (event) => {
    setExportSelected(event.target.value);
  };

  const [sidenavOpen, setSidenavOpen] = React.useState(true);
  //const location = useLocation();
  const mainContentRef = React.useRef(null);
 /* React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContentRef.current.scrollTop = 0;  
    Setting the initial state of sub map option when DVM = Bundles
    if(mapSelected === "Bundles")
      setMapSelected("Least CO2");
  }, [location]);*/

  //To check if clear ALL was clicked in order to stop map loading
  const [clear, setClear] = React.useState(false);
  const clearSelection = () => { 
    setClear(true); 
    setSolutionJson();
    setPortSelected({});
    setDateSelected();
    setTimeSelected("st");
    setVesselSelected("S")
    setEngineSelected("85%");
    setSailTypeSelected("367");
    setMapSelected("Isolines");
    setChartSelected({ y: "CO2 rate", x: "Cum.Time" });
    setExportSelected("sf");
  }

  //Convert product F to product A
  const convertBundleToRoute=(port, date, time, engine, stype)=>{
    //console.log("Checking function call: "+port+","+date+","+time+","+engine);
    if(port)
      setPortSelected(port);
    setDateSelected(new Date(date.substring(0,4)+"-"+date.substring(4,6)+"-"+date.substring(6)));
    setTimeSelected(time.substring(0,2)+":"+time.substring(2));
    setEngineSelected(engine+"%");
    setSailTypeSelected(stype);
  }

  // toggles collapse between mini sidenav and normal
  const toggleSidenav = (e) => {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
    }
    setSidenavOpen(!sidenavOpen);
  };

  //Get solution json to get date
  const [solutionJson, setSolutionJson] = React.useState();
  React.useEffect(() => {
    if (portSelected.fromValue && portSelected.toValue && 
      !(portSelected.fromValue === "from") && !(portSelected.toValue === "to") && 
      !(portSelected.fromValue === "WEST") && !(portSelected.fromValue === "EAST")){
      getSolutionsJsonFile(portSelected.fromValue+"_"+portSelected.toValue, vesselSelected).then(res => {
        if(res && res[0]!==null && res[1]!==null) 
          setSolutionJson({solArr: res[0], revSolArr:res[1]})
        else{
          alert("Failed to retrieve chart data. Please try selecting different ports or try again later.");
          //window.location = "/unavailable";
        }
      }).catch(function (error) {
         // console.log(error);
      });
    }
  },[portSelected, vesselSelected]);

  const [vesselName, setVesselName] = React.useState(vesselNamesMB[1]);
  //Get vessel/folder name based on user selections
  const setVesselFolderName = () => {
    if(vesselSelected === "S"){
      if(sailTypeSelected === "24")
        setVesselName(vesselNamesSB[1]);
      else if(sailTypeSelected === "60")
        setVesselName(vesselNamesSB[2]);
      else
        setVesselName(vesselNamesSB[0]);
    }
    else{
      if(engineSelected === "70%")
        setVesselName(vesselNamesMB[0]);
      else if(engineSelected === "100%")
        setVesselName(vesselNamesMB[2]);
      else
        setVesselName(vesselNamesMB[1]);
    }
  }
  
  // React.useEffect(()=>{    
  //   setState({selectedDVP:portSelected ,
  //     selectedDVD:(dateSelected)? (dateSelected.toISOString().substr(0, 10)) : undefined, 
  //     selectedDVT:timeSelected, 
  //     selectedDVE:engineSelected, 
  //     selectedDVM:mapSelected, 
  //     selectedDVL:chartSelected, 
  //     solutionJson:solutionJson,
  //   });
  // })

  //Map port marker reference and click
  const portMarkerRef = [];
  const handlePortMarkerRef = (index, node) => {
    portMarkerRef[index] = node;
  };
  const portMarkerClick=(index) => {      
    if (portMarkerRef[index]){
      //Reading code names
      const p = portMarkerRef[index].options.children.props.children[0];
      if(portSelected.fromValue==="from")
        setPortSelected({fromValue: p, toValue: "to"});
      if(portSelected.toValue==="to" && !(portSelected.fromValue==="from"))
        setPortSelected({fromValue: portSelected.fromValue, toValue: p});
    }
  };

  return (
    <>
      <Sidebar
        toggleSidenav={toggleSidenav}
        sidenavOpen={sidenavOpen}
        logo={{
          innerLink: "/",
          imgSrc: require("assets/img/brand/logo-grey.png").default,
          imgAlt: "...",
        }}
        clearSelection ={clearSelection}
        portSelected={portSelected}
        setPortSelected={setPortSelected}
        changePortSelectOptionHandler={changePortSelectOptionHandler}
        swapPortSelect={swapPortSelect}
        timeSelected={timeSelected}
        setTimeSelected={setTimeSelected}
        dateSelected={dateSelected}
        setDateSelected={setDateSelected}
        vesselSelected={vesselSelected}
        setVesselSelected={setVesselSelected}
        changeVesselSelectOptionHandler={changeVesselSelectOptionHandler}
        mapSelected={mapSelected}
        changeMapSelectOptionHandler={changeMapSelectOptionHandler}
        engineSelected={engineSelected}
        changeEngineSelectOptionHandler={changeEngineSelectOptionHandler}
        sailTypeSelected={sailTypeSelected}
        changeSailTypeSelectOptionHandler={changeSailTypeSelectOptionHandler}
        vesselName={vesselName}
        chartSelected={chartSelected}
        changeChartSelectOptionHandler={changeChartSelectOptionHandler}
        exportSelected={exportSelected}
        changeExportSelectOptionHandler={changeExportSelectOptionHandler}
        solutionJson = {solutionJson}
      />
      <div className="main-content" ref={mainContentRef}>
        <Header
          toggleSidenav={toggleSidenav}
          sidenavOpen={sidenavOpen}
          titleText={<><b>FRAME-VISIR: optimal routing for leisure boats</b></>}
        />
        <Dashboard 
          //selectedState={state}
          clearSelection ={clearSelection}
          selectedDVP={portSelected} 
          selectedDVD={(dateSelected)? (dateSelected.toISOString().substr(0, 10)) : undefined} 
          selectedDVT={timeSelected} 
          setVesselSelected={setVesselSelected}
          selectedDVV={vesselSelected}
          selectedDVE={engineSelected} 
          setEngineSelected={setEngineSelected}
          selectedDVS={sailTypeSelected}
          setSailTypeSelected={setSailTypeSelected}
          selectedDVM={mapSelected} 
          setMapSelected={setMapSelected}
          selectedDVL={chartSelected} 
          vesselName={vesselName}
          setVesselFolderName={setVesselFolderName}
          solutionJson={solutionJson}
          setSolutionJson = {setSolutionJson}
          handlePortMarkerRef={handlePortMarkerRef}
          portMarkerClick={portMarkerClick}
          convertBundleToRoute={convertBundleToRoute}
          clearValue = {clear}
        />
        <Footer />
      </div>
      {sidenavOpen ? (
        <div className="backdrop d-xl-none" onClick={toggleSidenav} />
      ) : null}
    </>
  );
}

export default AdminLayout;
