import { FormGroup, Input, Col, Row } from "reactstrap";
import React from "react";
import DatePicker, { CalendarContainer } from "react-datepicker";

const DVDT = React.memo((props) => {

    const { selectedDVP, selectedDVD, selectedDVT, selectedDVV, vesselName, changeSelectDateOptionHandler, changeSelectTimeOptionHandler, solutions } = props;

    //var powerDVE = (selectedDVE === "70%") ? 0.7 : ((selectedDVE === "85%") ? 0.85 : 1);
    var disableDate = false, disableTime = false;

    if (selectedDVP.fromValue === "WEST" || selectedDVP.fromValue === "EAST") {
        disableDate = true;
        disableTime = true;
        changeSelectTimeOptionHandler("ALL");
    }

    //To store the list of all dates
    var dateArr = [];
    //To store the list of all dates along with timesteps
    var dateTimeArr = [];
    if (solutions) {
        //console.log("DVDT props: ",props);
        solutions.solArr.forEach((el) => {
        
            if (el.Type === "dist" && el.Vessel === vesselName) {
                const depDate = el.depDateTime.split('_')[0];
                const formatDate = depDate.substring(0, 4) + "-" + depDate.substring(4, 6) + "-" + depDate.substring(6);
                if (!dateArr.includes(formatDate))
                    dateArr.push(formatDate);
                const depTime = el.depDateTime.split('_')[1];
                const formatTime = depTime.substring(0, 2) + ":" + depTime.substring(2, 4);
                dateTimeArr.push({ date: formatDate, time: formatTime });
            }
        });
        //console.log("Date array: ",dateArr)

        //To convert the dateArr from string date to date format
        dateArr.forEach((el, i, self) => {
            self[i] = new Date(el);
        })

        //To store the list of all dates mapped with timesteps in an array
        var formatDateTimeArr = [];
        dateTimeArr.forEach(function (item) {
            var existing = formatDateTimeArr.filter(function (v, i) {
                return v.date === item.date;
            });
            if (existing.length) {
                var existingIndex = formatDateTimeArr.indexOf(existing[0]);
                formatDateTimeArr[existingIndex].time = formatDateTimeArr[existingIndex].time.concat(item.time);
            }
            else {
                if (typeof item.time == 'string')
                    item.time = [item.time];
                formatDateTimeArr.push(item);
            }
        });

        //To make the range of dates enabled for user
        var maxDate = new Date(Math.max.apply(null, dateArr));
        var minDate = new Date(Math.min.apply(null, dateArr));

         // To handle missing route data of sailboat - missing all time values from one date
         if(dateArr && dateArr.length !== 0 && selectedDVD){
            var flag = 0;
            dateArr.forEach(date => {
                if(date.toDateString() === selectedDVD.toDateString())
                    flag = 1;
            });
            if(flag === 0){
                changeSelectDateOptionHandler(maxDate);
            }
        }

        //console.log("TIME ARRAY (for first date): ",formatDateTimeArr[0].time)

        //DVT should be updated with the changes in DVD
        var timeOptions, earliestTime;
        if (selectedDVD) {
            for (var i = 0; i < formatDateTimeArr.length; i++) {
                if ((selectedDVD.toISOString().substr(0, 10)) === formatDateTimeArr[i].date)
                    timeOptions = formatDateTimeArr[i].time;
                if ((minDate.toISOString().substr(0, 10)) === formatDateTimeArr[i].date)
                    earliestTime = formatDateTimeArr[i].time[0];
            }
            // To handle missing route data of sailboat - missing some time values
            if(timeOptions && timeOptions.length !== 0 && selectedDVT && selectedDVT !== "ALL" && selectedDVT !== "st" && !(timeOptions.includes(selectedDVT))){
                console.log("Missing time")
                changeSelectTimeOptionHandler(timeOptions[0]);
            }
        }

        //Default selections
        var DVTOptions;
        if (!selectedDVP.toValue || selectedDVP.toValue === "to") {
            disableDate = true;
            disableTime = true;
        }
        else if (selectedDVP.fromValue === "WEST" || selectedDVP.fromValue === "EAST") {
            disableDate = true;
            changeSelectTimeOptionHandler("ALL");
        }
        else {
            disableDate = false;
            if (!selectedDVD || !selectedDVT) {
                DVTOptions = null;
                //console.log("Default time:", earliestTime);
                changeSelectDateOptionHandler(minDate);
                changeSelectTimeOptionHandler(earliestTime);
            }
            else {
                if (timeOptions)
                    DVTOptions = timeOptions.map((el) => <option key={el} value={el}>{el}</option>);
                else
                    DVTOptions = null;
            }
        }

        if (!(selectedDVP.fromValue === "WEST" || selectedDVP.fromValue === "EAST") && selectedDVT === "ALL" && selectedDVD)
            disableDate = true;

    }
    else {
        disableDate = true;
        disableTime = true;
    }

    const onDateChange = (date) => {
        changeSelectDateOptionHandler(date);
    };

    const onTimeChange = (event) => {
        changeSelectTimeOptionHandler(event.target.value);
    };

    const backwardDateTime = () => {
        const convertedDVD = selectedDVD.toISOString().substring(0, 10);
        const dateArrLength = formatDateTimeArr.length;
        for (var i = 0; i < dateArrLength; i++) {
            for (var j = 0; j < formatDateTimeArr[i].time.length; j++) {
                if (convertedDVD === formatDateTimeArr[i].date && selectedDVT === formatDateTimeArr[i].time[j]) {
                    //First date and time
                    if (convertedDVD === formatDateTimeArr[0].date && selectedDVT === formatDateTimeArr[0].time[0]) {
                        changeSelectDateOptionHandler(new Date(formatDateTimeArr[dateArrLength - 1].date));
                        changeSelectTimeOptionHandler(formatDateTimeArr[dateArrLength - 1].time[formatDateTimeArr[dateArrLength - 1].time.length - 1]);
                    }
                    //First time but not first date
                    else if (selectedDVT === formatDateTimeArr[i].time[0] && !(convertedDVD === formatDateTimeArr[0].date)) {
                        changeSelectDateOptionHandler(new Date(formatDateTimeArr[i - 1].date));
                        changeSelectTimeOptionHandler(formatDateTimeArr[i - 1].time[formatDateTimeArr[i - 1].time.length - 1]);
                    }
                    else {
                        changeSelectTimeOptionHandler(formatDateTimeArr[i].time[j - 1]);
                    }
                }
            }
        }
    }

    const forwardDateTime = () => {
        const convertedDVD = selectedDVD.toISOString().substring(0, 10);
        const dateArrLength = formatDateTimeArr.length;
        for (var i = 0; i < dateArrLength; i++) {
            const timeArrLength = formatDateTimeArr[i].time.length;
            for (var j = 0; j < timeArrLength; j++) {
                if (convertedDVD === formatDateTimeArr[i].date && selectedDVT === formatDateTimeArr[i].time[j]) {
                    //Last date and time
                    if (convertedDVD === formatDateTimeArr[dateArrLength - 1].date && selectedDVT === formatDateTimeArr[dateArrLength - 1].time[timeArrLength - 1]) {
                        changeSelectDateOptionHandler(new Date(formatDateTimeArr[0].date));
                        changeSelectTimeOptionHandler(formatDateTimeArr[0].time[0]);
                    }
                    //Last time but not last date
                    else if (selectedDVT === formatDateTimeArr[i].time[timeArrLength - 1] && !(convertedDVD === formatDateTimeArr[dateArrLength - 1].date)) {
                        changeSelectDateOptionHandler(new Date(formatDateTimeArr[i + 1].date));
                        changeSelectTimeOptionHandler(formatDateTimeArr[i + 1].time[0]);
                    }
                    else {
                        changeSelectTimeOptionHandler(formatDateTimeArr[i].time[j + 1]);
                    }
                }
            }
        }
    }

    return (
        <Row className="row pl-5 pt-3">
            <Col md="9" xs="9" className="mr-0 ml-0 pl-0 pr-0">
                <FormGroup>
                    <Col>
                        {/*Current Input type="date" is used for the date picker, 
                I tried react-datepicker and react-date-time, but the calendar doesnt work well
                <Input
                    type="date"
                    value={selectedDVD}
                    disabled={disableDate}
                    min={minDate}
                    max={maxDate}
                    onKeyDown={(e) => e.preventDefault()}
                    onChange={onDateChange}
                />*/}
                        {/* Uncomment for react-datepicker */}
                        <DatePicker
                            id="example-date-input"
                            placeholderText="YYYY-MM-DD"
                            selected={selectedDVD}
                            disabled={disableDate}
                            disabledKeyboardNavigation={true}
                            onKeyDown={(e) => e.preventDefault()}
                            dateFormat="yyyy-MM-dd"
                            minDate={minDate}
                            maxDate={maxDate}
                            onChange={onDateChange}
                            calendarContainer={MyContainer}
                        //calendarClassName="pr--2"
                        />
                    </Col>
                </FormGroup>
                <FormGroup>
                    <Col>
                        <Input id="example-time-input" type="select" disabled={disableTime} value={selectedDVT} onChange={onTimeChange} >
                            <option value="st" hidden>Select time</option>
                            {DVTOptions}
                            <option>ALL</option>;
                        </Input>
                    </Col>
                    <p hidden={disableTime||disableDate} className="text-muted pl-3 pt-0 small"><small>[all times in UTC]</small></p>
                </FormGroup>
            </Col>
            <Col md="2" xs="2" className="mr-0 ml-0 pl-0 pr-0 pt-5">
                {(disableDate || disableTime)
                    ? <><i disabled className="ni ni-bold-left text-gray" /><i disabled className="ni ni-bold-right text-gray" /></>
                    : <><i onClick={backwardDateTime} style={{ cursor: 'pointer' }} className="ni ni-bold-left text-info" />
                        <i onClick={forwardDateTime} style={{ cursor: 'pointer' }} className="ni ni-bold-right text-info" /></>}
            </Col>
        </Row>
    );
});

export default DVDT;

const MyContainer = ({ className, children }) => {
    return (
        <div style={{ paddingRight: 4 }}>
            <CalendarContainer className={className}>
                <div style={{ position: "relative" }}>{children}</div>
            </CalendarContainer>
        </div>
    );
};

// function convertDate(dateString) {
//     // Split the date and time components of the string
//     const [date, time] = dateString.split(" ");
    
//     // Remove hyphens from the date component and concatenate the year, month, and day
//     const formattedDate = date.replace(/-/g, "");
    
//     // Remove colons from the time component and concatenate the hour and minute
//     const formattedTime = time.replace(/:/g, "").slice(0, -2);
    
//     // Concatenate the formatted date and time components with an underscore separator
//     const formattedDateTime = formattedDate + "_" + formattedTime;
    
//     return formattedDateTime;
// }
