import React from 'react';
import { CircleMarker, Popup } from "react-leaflet";
import {DMoptionsWP} from "static/divOptions.js"

class RouteMarkers extends React.Component {
  constructor(props) {
    super();

  }
  render() {
    return this.props.coords.map((Waypoint, index) => (
      <CircleMarker
        waypointId={Waypoint.props.waypoint_ID}
        center={[Waypoint.props.lat, Waypoint.props.lon]}
        pathOptions={this.props.pathOptions}
        radius={((this.props.selectedWP) && (this.props.type === this.props.selectedWP.type) && (Waypoint.props.waypoint_ID === this.props.selectedWP.id))? this.props.selectedWP.radius :4}
        fill={true}
        fillOpacity={false}
        eventHandlers={{
          click: (e) => {
            //console.log('route marker clicked', e);
            //e.target.openPopup();
            this.props.routeMarkerClick(this.props.type, e.target.options.waypointId, e.target._radius, this.props.map);
          },
          add: (e) => {
            if(e.target.getRadius() === 8){
              e.target.openPopup();
              // const props = this.props;
              // e.target.getPopup().on('remove', function() {
              //   props.routeMarkerClick(props.type, e.target.options.waypointId, e.target._radius, false);
              // });
            }
          }
        }}
      >
        <Popup className='popupwp' offset={[0, -1]} autoClose={false}>
          Lat: {Waypoint.props.lat.toFixed(4)+ " \u00B0N"} <br />
          Lon: {Waypoint.props.lon.toFixed(4) + " \u00B0E"} <br />
          --------------------------- <br />
          Date: {Waypoint.props.ISO_date.split("T")[0]} <br />
          Time: {(Waypoint.props.ISO_date.split("T")[1]).split("Z")[0]} <br />
          --------------------------- <br />
          SOG: {(Waypoint.props.SOG === null) ? null : Waypoint.props.SOG.toFixed(1) + " kts"} <br />
          {getVarPopupContent(Waypoint)}
          Parallel currents: {(Waypoint.props.w_parallel === null) ? null : Waypoint.props.w_parallel.toFixed(1) + " kts"} <br />
          Cross currents: {(Waypoint.props.w_cross === null) ? null : Waypoint.props.w_cross.toFixed(1) + " kts"} 
        </Popup>
      </CircleMarker>
    ))
  }
} export default RouteMarkers;

const getVarPopupContent = (Waypoint) =>{
  let varPopupContent = '';
  (Waypoint.props.wind_dir !== null)
  //Sailboat
  ? DMoptionsWP[1].forEach(wp => {
    varPopupContent += wp.displayName + ((Waypoint.props[wp.jsonId] === null) ? null : Waypoint.props[wp.jsonId].toFixed(1) + wp.units)+"\n";
    })
  //Motorboat
  : DMoptionsWP[0].forEach(wp => {
    varPopupContent += wp.displayName + ((Waypoint.props[wp.jsonId] === null) ? null : Waypoint.props[wp.jsonId].toFixed(1) + wp.units)+"\n";
  })
  return varPopupContent;
}
