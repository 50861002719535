import React from "react";
import {FormGroup,Input,Row,Col} from "reactstrap";
import {sailboatOptions} from "static/sidebarOptions.js"

const DVS = React.memo((props) => {
    //console.log("DVS props: ",props);
    const { selectedDVP, selectedDVT, selectedDVS, changeSelectOptionHandler } = props;
    
    var DVSOptions;
    var disableValue = true;

    if (!(selectedDVP.fromValue === "WEST" || selectedDVP.fromValue === "EAST" || selectedDVT === "ALL")) {
        disableValue = false;
        DVSOptions = sailboatOptions.map((el) => <option key={el.value} value={el.value}>{el.name}</option>);        
    }

    if(!selectedDVP.toValue || selectedDVP.toValue === "to" || !selectedDVT || selectedDVT === "st")
        disableValue = true;
        
    return (
        <Row className="row pl-5 pt-3">
            <Col md="10" xs="9" className="mr-0 ml-0 pl-0 pr-0">
                <FormGroup>
                    <Col>
                        <Input id="exampleFormControlSelect" type="select" value={selectedDVS} disabled={disableValue} onChange={changeSelectOptionHandler}>
                            {DVSOptions}
                        </Input>
                    </Col>
                </FormGroup>
            </Col>
        </Row>
    );

});

export default DVS;
