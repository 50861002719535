import React from "react";
import { FormGroup, Input, Row, Col } from "reactstrap";
import { exportOptions } from "static/sidebarOptions.js";
import { getExportFile } from "static/readData.js";


const DE = React.memo((props) => {
    //console.log("DE props: ",props);
    const { selectedDVP, selectedDVD, selectedDVT, selectedDVV, selectedDVS, selectedDVE, selectedDE, changeSelectOptionHandler } = props;

    const formattedDate = (selectedDVD) ? (selectedDVD.toISOString().substr(0, 10)) : undefined
    const formattedDVE = selectedDVE.split('%')[0];

    var options, disableValue = true, disableButton = true;

    const [state, setState] = React.useState({ fileDownloadUrl: null });
    React.useEffect(() => {
        if (!disableButton) {
            const path = selectedDVP.fromValue + "_" + selectedDVP.toValue;
            const date = (formattedDate) ? formattedDate.split('-')[0] + formattedDate.split('-')[1] + formattedDate.split('-')[2] : undefined;
            const time = (selectedDVT && selectedDVT !== "ALL" && selectedDVT !== "st") ? selectedDVT.split(':')[0] + selectedDVT.split(':')[1] : "st";
            const engineLoad = (formattedDVE !== "ALL") ? ((formattedDVE !== "100") ? "0" + formattedDVE : formattedDVE) : "ALL";
            const format = selectedDE.toLowerCase();
            getExportFile(path, date, time, engineLoad, selectedDVS, format, selectedDVV).then(res => {
                setState({ fileDownloadUrl: res })
            }).catch(function (error) {
                console.log(error);
            });
        }
    }, [selectedDVP, formattedDate, selectedDVT, formattedDVE, selectedDE, selectedDVS, selectedDVV, disableButton]);

    if (!(selectedDVP.fromValue === "WEST" || selectedDVP.fromValue === "EAST" || selectedDVT === "ALL")){
        disableValue = false;
        options = exportOptions.map((el) => <option key={el} value={el}>{el}</option>);
        if (selectedDE !== "sf") {
            disableButton = false;
        }
        else {
            disableButton = true;
        }
    }

    if (!selectedDVP.toValue || selectedDVP.toValue === "to" || !selectedDVT || selectedDVT === "st") {
        disableValue = true;
        disableButton = true;
    }
    return (
        <Row className="row pl-5 pt-3">
            <Col md="10" xs="9" className="mr-0 ml-0 pl-0 pr-0">
                <FormGroup>
                    <Col>
                        {<Input id="exampleFormControlSelect" type="select" disabled={disableValue} value={selectedDE} onChange={changeSelectOptionHandler}>
                            <option hidden value="sf">Select format</option>
                            {options}
                        </Input>}
                    </Col>
                    <Col hidden={disableButton} onClick={URL.revokeObjectURL(state.fileDownloadUrl)}>
                        <a id="exportSubmitButton"
                            href={state.fileDownloadUrl}
                        >Download</a>
                    </Col>
                </FormGroup>
            </Col>
        </Row>
    );
});

export default DE;
