import React from "react";
import ReactDOM from "react-dom";
// react library for routing
import { Router, Route, Switch, Redirect } from "react-router-dom";

//date-picker
import "react-datepicker/dist/react-datepicker.css"
// plugins styles from node_modules
import "react-notification-alert/dist/animate.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "@fullcalendar/common/main.min.css";
import "@fullcalendar/daygrid/main.min.css";
import "sweetalert2/dist/sweetalert2.min.css";
import "select2/dist/css/select2.min.css";
import "quill/dist/quill.core.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
// plugins styles downloaded
import "assets/vendor/nucleo/css/nucleo.css";
// core styles
import "assets/scss/argon-dashboard-pro-react.scss?v1.2.0";
import "assets/css/custom.css";
//map loading css
import "leaflet-loading/src/Control.Loading.css"
//react-alert
import 'react-toastify/dist/ReactToastify.css';

import AdminLayout from "layouts/Admin.js";
import OtherLayout from "layouts/Other.js";
import ServiceNotAvailable from "layouts/ServiceNotAvailable";

import { createBrowserHistory } from 'history';
import ReactGA from 'react-ga4';

ReactGA.initialize("x", { testMode: true });
ReactGA.initialize('G-G2K5KR7XGL'); // add your tracking id here.
//ReactGA.pageview(window.location.pathname + window.location.search);
// Send pageview with a custom path

export const history = createBrowserHistory();
history.listen((location) => {
  //ReactGA.send(location.pathname + location.search);
  ReactGA.send({ hitType: "pageview", page: location.pathname });
});

ReactDOM.render(
  <Router history={history}>
    <Switch>
      <Route path="/other" render={(props) => <OtherLayout {...props} />} />
      <Route path="/unavailable" render={(props) => <ServiceNotAvailable {...props} />} />
      <Route path="/" render={(props) => <AdminLayout {...props} />} />
      <Redirect from="*" to="/" />
    </Switch>
  </Router>,
  document.getElementById("root")
);
