
import React, { useState } from "react";
import { Collapse, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";

const Submenu = (props) => {
  const [collapsed, setCollapsed] = useState(true);
  const toggle = () => setCollapsed(!collapsed);
  const { icon, title, items } = props;

  return (
    <div>
      <NavItem>
        <NavLink
          data-toggle="collapse"
          aria-expanded={!collapsed}
          className={classnames({
            active: !collapsed,
          })}
          onClick={toggle}
        >
          <i className={icon} />
          <span className="nav-link-text">{title}</span>
        </NavLink>
        <Collapse isOpen={!collapsed}>
          {items}
        </Collapse>
      </NavItem>
    </div>
  );
};

export default Submenu;
