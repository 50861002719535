import React from "react";
import { Card, CardBody, CardImg, Col, Row } from "reactstrap";
//import ReactGA from 'react-ga';
function News() {

  // React.useEffect(() => {
  //   ReactGA.initialize('G-G2K5KR7XGL'); 
  //   ReactGA.pageview(window.location.pathname + window.location.search);
  // }, []);

  return (
    <>
      <div>
        <h1 className="text-white text-center text-lg-left display-3 header header-dark bg-info pb-6 pl-lg-8">
          NEWS
        </h1>
        <Row className="mt--5 pl-lg-8 pr-lg-8">
          {/* <Col lg="6" className="pb-1">
            <Card>
              <a href="https://zenodo.org/record/7400954" target="_blank" rel="noreferrer">
                <CardImg
                alt="..."
                src={require("assets/img/news/first-year-evol.png").default}
                top
                className="p-2"/>
              </a>
              <CardBody className="text-justify">
                <h3 className="heading text-info">EVALUATION OF FIRST YEAR OF OPERATIONS </h3>
                <p>
                Least-CO2 routes computed by GUTTA-VISIR in the time frame November 2021-September 2022 were assessed. The aim was to determine the distribution of both the CO2 and CII savings, also in relation to the location of the routes within the Adriatic Sea. <br />
                It was found that the maximum CO2 savings are related to the average sea state, that the CO2 savings on any given route are nearly exponentially distributed, and that the largest mean savings are obtained for routes in the southern part of the basin. <br/>
                Find more in this <a target="_blank" href="https://zenodo.org/record/7400954" rel="noreferrer">presentation</a>.
                </p>
              </CardBody>
            </Card>
          </Col>
          <Col lg="6" className="pb-1">
            <Card>
              <a href="https://www.mdpi.com/2077-1312/9/2/115" target="_blank" rel="noreferrer">
                <CardImg
                  alt="..."
                  src={require("assets/img/news/visir-2-paper.png").default}
                  top
                  className="p-2"/>
              </a>
              <CardBody className="text-justify">
                <h3 className="heading text-info">Paper on VISIR-2 published </h3>
                <p>
                  On January 23, 2021, a peer-reviewed paper on the VISIR model version 2 was published in the <a href="https://www.mdpi.com/2077-1312/9/2/115" target="_blank" rel="noreferrer">Journal of Marine Science and Engineering</a>.<br /><br />
                  The manuscript includes the description of the methodology for estimating ferry performance at sea, computing least-CO<sub>2</sub> routes, and assessing their contribution to the reduction of the CII (carbon intensity indicator). A case study was provided, with several routes in the Adriatic for a given date. The uncertainties of the method were also discussed.
                </p>
              </CardBody>
            </Card>
          </Col> */}
          <Col lg="6" className="pb-1 text-center">
            <Card className="align-items-center justify-content-center">
              <img
                alt="..."
                width="50%"
                src={require("assets/img/news/EGUlogo.png").default}
                top
                className="p-2"
              />
              <CardBody className="text-justify">
                <h3 className="heading text-info">VISIR-2: preprint and source code published </h3>
                <p>
                <i>Nov 16<sup>th</sup>, 2023:</i> A  <a href="https://egusphere.copernicus.org/preprints/2023/egusphere-2023-2060/" target="_blank" rel="noreferrer">preprint</a> detailing the Python-coded VISIR-2 ship routing model has recently been published.<br/>
                  VISIR-2, an open-source model, is designed to calculate least-CO 2 routes in the presence of waves and currents. Additionally, it determines least-time routes for sailboats, considering both currents and leeway. This modular, Python-coded system has been
                  validated and is notably ten times faster than its predecessor, VISIR-1, in computing optimal paths.<br/>
                  The <a href="https://zenodo.org/records/8305527" target="_blank" rel="noreferrer">source code</a> of VISIR-2, accompanied by input datasets, has been released on Zenodo. A video showcasing one-year least-CO2 optimal routes computed using VISIR-2 is accessible <a href="https://av.tib.eu/media/62912" target="_blank" rel="noreferrer">here</a>. Additionally, a corresponding video featuring optimal routes for a sailboat can be found <a href="https://av.tib.eu/media/62913" target="_blank" rel="noreferrer">here</a>.
                </p>
              </CardBody>
            </Card>
          </Col>
          <Col lg="6" className="pb-1">
            <Card>
              <CardImg
                alt="..."
                src={require("assets/img/news/frame-visir.png").default}
                top
                className="p-2"
              />
              <CardBody className="text-justify">
                <h3 className="heading text-info">FRAME-VISIR in operations </h3>
                <p>
                  <i>May 29<sup>th</sup>, 2023:</i> FRAME-VISIR entered into operations and is publicly available at <a href="https://frame-visir.eu">https://frame-visir.eu</a><br /><br />
                  For raising bugs or providing a feedback, please go to the <a href="/other/feedback">Feedback</a> section.
                </p>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default News;