const portOptions = ["Bar_MEBAR", "Brindisi_ITBDS", "Dubrovnik_HRDBV", "Cattolica_ITCCL", "Lefkada_GRLEV", "Loparo_HRLPR", "Monfalcone_ITMNF", "Monopoli_ITMNP", "Numana_ITNUM", "Ortona_ITOTN", "Othoni_GROTH", "Otranto_ITOTO", "Pola_HRPUY", "Rimini_ITRMI", "Sibenik_HRSIB", "Spalato_HRSPU", "Termoli_ITTMI", "Trani_ITTNI", "Valona_ALVOA", "Vieste_ITVIE", "Zara_HRZAD"];
const toOptions = [    
    {from: "Brindisi_ITBDS", to: ["Othoni_GROTH", "Valona_ALVOA", "Vieste_ITVIE"]},
    {from: "Cattolica_ITCCL", to: ["Loparo_HRLPR", "Zara_HRZAD"]},
    {from: "Monopoli_ITMNP", to: ["Bar_MEBAR"]},
    {from: "Numana_ITNUM", to: ["Sibenik_HRSIB", "Zara_HRZAD"]},
    {from: "Ortona_ITOTN", to: ["Dubrovnik_HRDBV", "Spalato_HRSPU", "Termoli_ITTMI"]},
    {from: "Otranto_ITOTO", to: ["Lefkada_GRLEV"]},
    {from: "Rimini_ITRMI", to: ["Monfalcone_ITMNF", "Pola_HRPUY"]},
    {from: "Termoli_ITTMI", to: ["Dubrovnik_HRDBV", "Ortona_ITOTN", "Sibenik_HRSIB"]},
    {from: "Trani_ITTNI", to: ["Bar_MEBAR", "Dubrovnik_HRDBV"]},
    {from: "Valona_ALVOA", to: ["Brindisi_ITBDS", "Dubrovnik_HRDBV"]},
    {from: "Vieste_ITVIE", to: ["Brindisi_ITBDS", "Spalato_HRSPU"]},
    {from: "Pola_HRPUY", to: ["Rimini_ITRMI", "Zara_HRZAD"]},
    {from: "Spalato_HRSPU", to: ["Dubrovnik_HRDBV", "Ortona_ITOTN", "Vieste_ITVIE"]},
    {from: "Zara_HRZAD", to: ["Cattolica_ITCCL", "Numana_ITNUM", "Pola_HRPUY"]},
    {from: "Loparo_HRLPR", to: ["Cattolica_ITCCL"]},
    {from: "Bar_MEBAR", to: ["Monopoli_ITMNP", "Trani_ITTNI"]},
    {from: "Sibenik_HRSIB", to: ["Numana_ITNUM", "Termoli_ITTMI"]},
    {from: "Dubrovnik_HRDBV", to: ["Ortona_ITOTN", "Spalato_HRSPU", "Termoli_ITTMI", "Trani_ITTNI", "Valona_ALVOA"]},
    {from: "Othoni_GROTH", to: ["Brindisi_ITBDS"]},
    {from: "Lefkada_GRLEV", to: ["Otranto_ITOTO"]},
    {from: "Monfalcone_ITMNF", to: ["Rimini_ITRMI"]}
];

const timeOptions = ["00:00", "03:00", "06:00", "09:00", "12:00", "15:00", "18:00", "21:00"];

//const vesselOptions = [{ type: "M", name: "Flybridge" }];
const vesselOptions = [{type: "S", name: "Sailboat"}, {type: "M", name: "Flybridge"}, ];

const mapOptionsSB = ["Isolines", "Sea currents", "Winds"];
const mapOptionsMB = ["Isolines", "Sea currents", "Waves"];
const mapOptionsDVPALL = ["Least-CO2", "Least-time"];
const mapOptionsDVTALL = ["Bundles", "Animation"];
const mapOptionsDVTALLAnimation = ["Sea currents", "Waves"];
const mapOptionsDVTALLBundles = ["Least-CO2", "Least-time"];

const mblinechartOptionsY = ["CO2 rate", "Speed", "Course", "Wave height"];
const sblinechartOptionsY = ["Speed", "Course", "Wind speed", "Wind direction"];
const linechartOptionsX = ["Cum.Time", "Cum.Distance"];

const engineOptions = ["70%", "85%", "100%"];
const vesselNamesMB = ["unige_Flybridge__P070", "unige_Flybridge__P085", "unige_Flybridge__P100"];
const sailboatOptions = [{name: "First 367", value: "367"}, {name: "J24", value: "24"}, {name: "Swan 60FD", value: "60"}]
const vesselNamesSB = ["unige_First_367__Fcu-wi", "unige_J24__Fcu-wi", "unige_Swan_60FD__Fcu-wi"];

const exportOptions = ["JSON", "XML"];


export {
    portOptions, toOptions,
    timeOptions,
    vesselOptions, vesselNamesMB, vesselNamesSB,
    mapOptionsMB, mapOptionsSB,  mapOptionsDVPALL, mapOptionsDVTALL, mapOptionsDVTALLAnimation, mapOptionsDVTALLBundles,
    mblinechartOptionsY, sblinechartOptionsY, linechartOptionsX,
    engineOptions, sailboatOptions,
    exportOptions
};