import React from "react";
import {FormGroup,Input,Row,Col} from "reactstrap";
import {vesselOptions} from "static/sidebarOptions.js"

const DVV = React.memo((props) => {
    //console.log("DVV props: ",props);
    const { selectedDVV, changeSelectOptionHandler } = props;
    
    const DVVOptions = vesselOptions.map((el) => <option key={el.type} value={el.type}>{el.name}</option>);  
        
    return (
        <Row className="row pl-5 pt-3">
            <Col md="10" xs="9" className="mr-0 ml-0 pl-0 pr-0">
                <FormGroup>
                    <Col>
                        <Input id="exampleFormControlSelect" type="select" value={selectedDVV} onChange={changeSelectOptionHandler}>
                            {DVVOptions}
                        </Input>
                    </Col>
                </FormGroup>
            </Col>
        </Row>
    );

});

export default DVV;
