import React from "react";
import { TileLayer, MapContainer, useMap } from "react-leaflet";
import PortsMarkers from "./PortsMarkers.js";
import GuttaRoute from "./GuttaRoute.js";
import 'leaflet/dist/leaflet.css';
import "leaflet-loading";
import ports from "../../static/ports.js";

const mapCenterLat = 42.0446688;
const mapCenterLng = 16.963513;
const mapDeafultZoom = 6;
let voyageInfo = "";

function ChangeView({ center, zoom, infoForChangeView }) {
  const map = useMap();
  
  if (voyageInfo !== infoForChangeView 
    && !infoForChangeView.includes("_to") 
    && !infoForChangeView.includes("undefined")
    && !infoForChangeView.includes("WEST")) {

    voyageInfo = infoForChangeView.split("/")[0];
    var dep = voyageInfo.split("_")[0];
    var arr = voyageInfo.split("_")[1];
    var depObject = ports.find( record => record.code === dep);
    var arrObject = ports.find( record => record.code === arr);

    var centerLat = (parseFloat(depObject.lat) + parseFloat(arrObject.lat))/2;
    var centerLng = (parseFloat(depObject.lng) + parseFloat(arrObject.lng))/2;
    
    map.setView([centerLat, centerLng], 7);
  } else if (infoForChangeView.includes("undefined") || infoForChangeView.includes("WEST")){
      map.setView([mapCenterLat, mapCenterLng], mapDeafultZoom);
  }

  return null;
}

class MapDM extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      route: []
    }
    this.mapRef = React.createRef();
  }

  render() {
    const path = this.props.state.port.fromValue + "_" + this.props.state.port.toValue;
    const sDate = this.props.state.date;
    const sTime = this.props.state.time;
    const engine = (this.props.state.power !== "ALL") ? ((this.props.state.power !== "100") ? "0" + this.props.state.power : this.props.state.power) : "ALL";
    const vessel = this.props.state.vessel;
    const vesselName = this.props.vesselName;
    const route = this.state;
    const mapOption = this.props.mapOption;
    const ref = this.props.ref;
    const voyageArr = this.props.voyage;
    //const infoForChangeView = null;
    const map = this.mapRef.current;

    route[0] = null;

    if (!(path.includes("from")) && !(path.includes("to")) && !voyageArr
      && !(path.includes("EAST")) && !(path.includes("WEST"))) {
      if (map !== null)
        map.fireEvent('dataloading');
        //console.log("guttaroute");
    }

    if(this.props.clearValue){
      if (map !== null) {
        for(var i in map._layers){
          if(map._layers[i]._path) {
            map.removeLayer(map._layers[i]);
          }
        }
        map._controlCorners.bottomright.innerHTML = '</>';
        map.fireEvent('dataload');
       //console.log("clear all was hit")
      }
    }

    if (!(path.includes("from")) && !(path.includes("to")) && !(sTime === "st")) {
      /** Case 1: DVP=ALL, DVT=ALL **/
      if (path.includes("WEST") || path.includes("EAST")) {
        if (mapOption === "Least-time") {
          //show bundles of least-time
          route[0] = <GuttaRoute mapRef={this.mapRef} product="AllF" path={path}  leastSelection="time" selectedBundle={this.props.state.selectedBundle} routeBundleClick={this.props.routeBundleClick}  convertBundleToRoute={this.props.convertBundleToRoute}/>;
        }
        else {
          //show bundles of least-CO2
          route[0] = <GuttaRoute mapRef={this.mapRef} product="AllF" path={path} vessel={vessel} leastSelection="CO2t" selectedBundle={this.props.state.selectedBundle} routeBundleClick={this.props.routeBundleClick} convertBundleToRoute={this.props.convertBundleToRoute}/>;
        }
      }
      /** Case 2: DVP!=ALL, DVT=ALL **/
      else if (sTime === "ALL" && !(path.includes("WEST") || path.includes("EAST"))) {
        if (mapOption === "Animation" || mapOption === "Sea currentsDVTAll") {
          //show current animation
        }
        else if (mapOption === "WavesDVTAll") {
          //show waves animation
        }
        else if (mapOption === "Least-timeDVTAll")
          route[0] = <GuttaRoute mapRef={this.mapRef} product="f" path={path} vessel={vessel} leastSelection="time" selectedBundle={this.props.state.selectedBundle} routeBundleClick={this.props.routeBundleClick} convertBundleToRoute={this.props.convertBundleToRoute}/>;
        else
          route[0] = <GuttaRoute mapRef={this.mapRef} product="f" path={path} vessel={vessel} leastSelection="CO2t" selectedBundle={this.props.state.selectedBundle} routeBundleClick={this.props.routeBundleClick} convertBundleToRoute={this.props.convertBundleToRoute}/>
      }
      /** Case 3: DVP!=ALL, DVT!=ALL **/
      else {
        if (voyageArr) {
          if (map !== null) {
            map.fireEvent('dataload');
            //console.log("route load")
            //map.flyTo([42.0446688, 15.963513],6);
          }
          route[0] = <GuttaRoute mapRef={this.mapRef} product="a" path={path} vessel={vessel} startDate={sDate} startTime={sTime} engine={engine} vesselName={vesselName} voyageArr={voyageArr} layer={mapOption} selectedWP={this.props.state.selectedWP} routeMarkerClick={this.props.routeMarkerClick} />
        }
      }
    }

    return (
      <MapContainer
        //ref={(ref) => { this.map = ref; }}
        //ref={ref}
        ref={ref}
        center={[mapCenterLat, mapCenterLng]}
        zoom={mapDeafultZoom}
        id="map-left"
        class="map-canvas"
        attributionControl={false}
        loadingControl={true}
        whenCreated={(map) => { this.mapRef.current = map }}
      >

        {/* <RemovePolylines /> */}
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
        />

        {/* Highlight port markers that are selected */}
        <PortsMarkers path={path} portMarkerRef={this.props.portMarkerRef} portMarkerClick={this.props.portMarkerClick} />

        {/*Calling Gutta Route based on siebar selection */}
        {route[0]}

        {/* centering map */}
        {this.props.voyage != null ?
          <ChangeView center={[mapCenterLat, mapCenterLng]} zoom={mapDeafultZoom} 
          infoForChangeView={this.props.state.port.fromValue + "_" + this.props.state.port.toValue}
          //infoForChangeView={this.props.voyage.name.split("/")[0]} 
          />
          : ""}
      </MapContainer>
    );
  }
}

export default MapDM;