/*eslint-disable*/
import React from "react";

// reactstrap components
import { NavItem, NavLink, Nav, Container, Row, Col } from "reactstrap";

//todo: update links
const Footer = React.memo(() => {
  return (
    <>
      <Container fluid>
        <footer className="footer pt-0 pb-0">
          <Row className="align-items-center justify-content-lg-between">
            <Col>
              <Nav className="nav-footer align-items-center justify-content-center">
                <NavItem  className="pt-3">
                    <NavLink
                      href="https://www.cmcc.it/"
                      target="_blank"
                    >
                      <img
                        alt="CMCC"
                        width="100"
                        src= {require("assets/img/partners/13_CMCC.png").default}
                      />
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="http://www.corila.it/en"
                      target="_blank"
                    >
                      <img
                        alt="Corila"
                        width="50"
                        src= {require("assets/img/partners/0_Corila.png").default}
                      />
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="https://www.comune.monfalcone.go.it/"
                      target="_blank"
                    >
                      <img
                        alt="Falco Tracciato"
                        width="35"
                        src= {require("assets/img/partners/1_FalcoTracciato.png").default}
                      />
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="https://www.fondazioneitl.org/en/"
                      target="_blank"
                    >
                      <img
                        alt="ITL"
                        width="120"
                        src= {require("assets/img/partners/2_ITL.png").default}
                      />
                    </NavLink>
                  </NavItem>
                  <NavItem className="pt-3">
                    <NavLink
                      href="http://asset.regione.puglia.it/"
                      target="_blank"
                    >
                      <img
                        alt="Asset"
                        width="70"
                        src= {require("assets/img/partners/3_Asset.png").default}
                      />
                    </NavLink>
                  </NavItem>
                  <NavItem className="pt-3">
                    <NavLink
                      href="https://svemarche.eu/"
                      target="_blank"
                    >
                      <img
                        alt="SVEM"
                        width="100"
                        src= {require("assets/img/partners/4_SVEM.png").default}
                      />
                    </NavLink>
                  </NavItem>
                  <NavItem  className="pt-3">
                    <NavLink
                      href="https://www.arapabruzzo.it/"
                      target="_blank"
                    >
                      <img
                        alt="ARAP"
                        width="100"
                        src= {require("assets/img/partners/5_Arap.png").default}
                      />
                    </NavLink>
                  </NavItem>
                  <NavItem  className="pt-3">
                    <NavLink
                      href="https://www.termoli.net/"
                      target="_blank"
                    >
                      <img
                        alt="TER-MOLI"
                        width="45"
                        src= {require("assets/img/partners/6_AZ.SOGG.TUR.png").default}
                      />
                    </NavLink>
                  </NavItem>
                  <NavItem  className="pt-3">
                    <NavLink
                      href="https://luun.hr/"
                      target="_blank"
                    >
                      <img
                        alt="UMAG"
                        width="60"
                        src= {require("assets/img/partners/7_LUUmagNo.png").default}
                      />
                    </NavLink>
                  </NavItem>
                  <NavItem  className="pt-3">
                    <NavLink
                      href="https://www.pgz.hr/"
                      target="_blank"
                    >
                      <img
                        alt="PGZ"
                        width="25"
                        src= {require("assets/img/partners/8_PGZ.png").default}
                      />
                    </NavLink>
                  </NavItem>
                  <NavItem  className="pt-3">
                    <NavLink
                      href="http://www.cpa-zadar.hr/en"
                      target="_blank"
                    >
                      <img
                        alt="CPA-Zadar"
                        width="70"
                        src= {require("assets/img/partners/9_ZLU.png").default}
                      />
                    </NavLink>
                  </NavItem>
                  <NavItem  className="pt-3">
                    <NavLink
                      href="https://www.portauthority-sibenik.hr/"
                      target="_blank"
                    >
                      <img
                        alt="Sibenik"
                        width="65"
                        src= {require("assets/img/partners/10_LUCKAUPRAVA.png").default}
                      />
                    </NavLink>
                  </NavItem>
                  {/* <NavItem  className="pt-3">
                    <NavLink
                      //href="https://www.adspmam.it/"
                      //target="_blank"
                    >
                      <img
                        alt="PFRI"
                        width="80"
                        src= {require("assets/img/partners/11_pfri.png").default}
                      />
                    </NavLink>
                  </NavItem> */}
                  <NavItem  className="pt-3">
                    <NavLink
                      href="https://www.logoteam.hr/en/"
                      target="_blank"
                    >
                      <img
                        alt="LogoTeam"
                        width="80"
                        src= {require("assets/img/partners/12_Logoteam.png").default}
                      />
                    </NavLink>
                  </NavItem>
                  <NavItem  className="pt-3">
                    <NavLink
                      href="http://lucka-uprava-senj.hr/"
                      target="_blank"
                    >
                      <img
                        alt="SENJ"
                        width="60"
                        src= {require("assets/img/partners/14_SENJ.png").default}
                      />
                    </NavLink>
                  </NavItem>
                  <NavItem  className="pt-3">
                    <NavLink
                      href="https://mmpi.gov.hr/"
                      target="_blank"
                    >
                      <img
                        alt="MMPI"
                        width="150"
                        src= {require("assets/img/partners/15_MMPI.png").default}
                      />
                    </NavLink>
                  </NavItem>
              </Nav>
            </Col>
          </Row>
          <Row className="align-items-center justify-content-lg-between">
            <Col>
              <div className="copyright text-center text-muted">
                {/* <p style={{fontSize: 13.5}}>
                This web application was funded by the European Regional Development Fund through the Italy-Croatia
                  Interreg programme, project GUTTA, grant number 10043587
                </p> */}
              </div>
            </Col>
          </Row>
        </footer>
      </Container>
    </>
  );
});

export default Footer;
