import React from "react";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import YoutubeEmbed from "../static/YoutubeEmbed";
//import ReactGA from 'react-ga';

function About() {

  // React.useEffect(() => {
  //   ReactGA.initialize('G-G2K5KR7XGL'); 
  //   ReactGA.pageview(window.location.pathname + window.location.search);
  // }, []);

  return (
    <>
      <div>
        <h1 className="text-white text-center text-lg-left display-3 header header-dark bg-info pb-6 pl-lg-8">
          ABOUT
        </h1>
        <Col className="pb-1 mt--5 pl-lg-8 pr-lg-8">
          <Card>
            <CardBody className="text-justify">
              <p>FRAME-VISIR is a decision support system for leisure boats in the Adriatic Sea. Its main purpose is to provide least-time routes for sailboats and least-CO<sub>2</sub> routes for motorboats, based on forecast meteo-marine conditions. <br /> <br />

                FRAME-VISIR is based on three main components:
                <ul>
                  <li> Environmental forecast fields (wave and sea-surface currents) from the <a href="https://marine.copernicus.eu/" target="_blank" rel="noreferrer">Copernicus Marine Service</a> and wind from the <a href="https://www.ecmwf.int/" target="_blank" rel="noreferrer">European Centre for Medium-Range Weather Forecasts</a> through a dedicated FTP provided by Aeronautica Militare. </li>
                  <li> The VISIR-2 model in its operational version, deployed at the <a href="https://www.cmcc.it/super-computing-center-scc" target="_blank" rel="noreferrer">CMCC</a>’s High Performance Computing facility in Lecce (Italy). </li>
                  <li> This webservice for browsing the results. </li>
                </ul>
                The VISIR-2 model was first documented in <a href="https://www.mdpi.com/2077-1312/9/2/115" target="_blank" rel="noreferrer">this</a> paper. This model is based on a graph-search method working with dynamic edge weights. It keeps into account both coastline and bathymetry, enabling computation of optimal routes even in archipelagic domains such as coastal Croatia. Information and source code from the previous versions of VISIR versions can be found <a href="http://www.visir-model.net/" target="_blank" rel="noreferrer">here</a>.<br /><br />

                The vessel's sea-keeping and emissions are based on mathematical models evaluated by the <a href="http://www.diten.unige.it/index.php?lang=it" target="_blank" rel="noreferrer">University of Genoa</a>. The sailboat polar diagrams used were based on WinDesign VPP - a tool making use of hydrodynamic and aerodynamic models. The motorboat model is based on a first-order Boundary Element Method based on Rankine source distributions over the hull and the free surface.

                The time or CO<sub>2</sub> savings deriving from the VISIR optimal routes are computed for various routes, departure times, and engine loads. <br /><br />
              </p>
            </CardBody>
          </Card>

          <Card>
            <CardBody className="text-justify">
              <Row>
                <Col xl="6">
                  <Row>
                    <Col xl="3">
                      <a href="https://framesport.eu/?doing_wp_cron=1701166946.7603039741516113281250" target="_blank" rel="noreferrer">
                        <img
                          width="100%"
                          alt="logo"
                          src={require("assets/img/brand/logo-grey.png").default}
                        />
                      </a>
                    </Col>
                    <Col xl="9">
                      <p>FRAMESPORT aimed at supporting a homogeneous and integrated improvement of Adriatic
                        small ports&#39; sustainability, competitiveness, and attractiveness through three main
                        objectives.</p>
                    </Col>
                  </Row>
                  <p>
                    The first one involved delivering a strategic framework to guide their future development in
                    the long run. The second specific objective was the realization of an ICT platform as a virtual
                    space, available for users and stakeholders, containing results from the implementation of
                    pilot actions and best practices, suggestions, and proposals for Adriatic small ports&#39;
                    development and management. FRAME-VISIR is an outcome from this objective. The third
                    specific objective aimed to increase competences for a harmonized planning and
                    management of small ports, contributing to elevating their role as drivers for the
                    sustainable growth of coastal areas.
                  </p>
                </Col>
                <Col xl="6">
                  <YoutubeEmbed width="100%" url='https://www.youtube.com/embed/Vjrl8Q5BX4E' />
                </Col>
              </Row>
            </CardBody>
          </Card>

          <Card>
            <CardBody className="text-justify">
              <p>Another ship-routing service powered by VISIR-2 is available at <a href="https://www.gutta-visir.eu/" target="_blank" rel="noreferrer">https://www.gutta-visir.eu/</a>. GUTTA-VISIR is a decision support system for ferries in the Adriatic Sea. Its main purpose is to provide operational least-CO<sub>2</sub>  ferry routes, depending on forecast meteo-marine conditions. GUTTA-VISIR focuses on cross-border routes between Italy and Croatia, Montenegro, Albania and Greece.
              </p>
            </CardBody>
          </Card>
          {/* <Card className="p-5">
            <CardTitle><h2 className="text-info">GUTTA project final video</h2></CardTitle>
            <YoutubeEmbed url = 'https://www.youtube.com/embed/JyEhrcsK19A'/>
          </Card> */}
        </Col>
      </div>
    </>
  );
}

export default About;