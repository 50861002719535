import React from "react";
import {FormGroup,Input,Row,Col} from "reactstrap";
import {mblinechartOptionsY, sblinechartOptionsY, linechartOptionsX} from "static/sidebarOptions.js"

const DVL = React.memo((props) => {
    //console.log("DVL props: ",props);
    const { selectedDVV, selectedDVL, changeSelectOptionHandler } = props;
    
    const DVLXOptions = linechartOptionsX.map((el) => <option key={el} value={el}>{el}</option>);
    var DVLYOptions;
    if(selectedDVV === "S")
        DVLYOptions = sblinechartOptionsY.map((el) => <option key={el} value={el}>{el}</option>);    
    else
        DVLYOptions = mblinechartOptionsY.map((el) => <option key={el} value={el}>{el}</option>);   
        
    return (
        <Row className="row pl-5 pt-3">
            <Col md="10" xs="9" className="mr-0 ml-0 pl-0 pr-0">
                <FormGroup>
                    <Col>
                        <Input id="exampleFormControlSelect4" type="select" name="y" value={selectedDVL.y} onChange={changeSelectOptionHandler}>
                            {DVLYOptions}
                        </Input>
                    </Col>
                </FormGroup>
                <FormGroup>
                    <Col>
                        <Input id="exampleFormControlSelect3" type="select" name="x" value={selectedDVL.x} onChange={changeSelectOptionHandler}>
                            {DVLXOptions}
                        </Input>
                    </Col>
                </FormGroup>
            </Col>
        </Row>
    );

});

export default DVL;
