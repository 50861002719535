const coords = [
  {
      code: "ITBDS",
      name: "Brindisi",
      lat: 40.6456,
      lng: 17.9604,
      to: [
          "ALVOA",
          "GROTH",
          "ITVIE"
      ]
  },
  {
      code: "ITCCL",
      name: "Cattolica",
      lat: 43.9695,
      lng: 12.7493,
      to: [
          "HRZAD",
          "HRLPR"
      ]
  },
  {
      code: "ITMNP",
      name: "Monopoli",
      lat: 40.9564,
      lng: 17.3034,
      to: [
          "MEBAR"
      ]
  },
  {
      code: "ITNUM",
      name: "Numana",
      lat: 43.50933,
      lng: 13.62554,
      to: [
          "HRZAD",
          "HRSIB"
      ]
  },
  {
      code: "ITOTN",
      name: "Ortona",
      lat: 42.3522,
      lng: 14.4206,
      to: [
          "HRSPU",
          "HRDBV",
          "ITTMI"
      ]
  },
  {
      code: "ITOTO",
      name: "Otranto",
      lat: 40.1474,
      lng: 18.4954,
      to: [
          "GRLEV"
      ]
  },
  {
      code: "ITRMI",
      name: "Rimini",
      lat: 44.0728,
      lng: 12.5717,
      to: [
          "HRPUY",
          "ITMNF"
      ]
  },
  {
      code: "ITTMI",
      name: "Termoli",
      lat: 42.0041,
      lng: 15.004,
      to: [
          "HRSIB",
          "HRDBV",
          "ITOTN"
      ]
  },
  {
      code: "ITTNI",
      name: "Trani",
      lat: 41.28117,
      lng: 16.42101,
      to: [
          "MEBAR",
          "HRDBV"
      ]
  },
  {
      code: "ALVOA",
      name: "Valona",
      lat: 40.45955,
      lng: 19.46134,
      to: [
          "HRDBV",
          "ITBDS"
      ]
  },
  {
      code: "ITVIE",
      name: "Vieste",
      lat: 41.88664,
      lng: 16.17619,
      to: [
          "HRSPU",
          "ITBDS"
      ]
  },
  {
      code: "HRPUY",
      name: "Pola",
      lat: 44.8705,
      lng: 13.8323,
      to: [
          "HRZAD",
          "ITRMI"
      ]
  },
  {
      code: "HRSPU",
      name: "Spalato",
      lat: 43.5201,
      lng: 16.4389,
      to: [
          "HRDBV",
          "ITOTN",
          "ITVIE"
      ]
  },
  {
      code: "HRZAD",
      name: "Zara",
      lat: 44.1238,
      lng: 15.2174,
      to: [
          "ITCCL",
          "ITNUM",
          "HRPUY"
      ]
  },
  {
      code: "HRLPR",
      name: "Loparo",
      lat: 44.83609,
      lng: 14.72314,
      to: [
          "ITCCL"
      ]
  },
  {
      code: "MEBAR",
      name: "Bar",
      lat: 42.0928,
      lng: 19.0798,
      to: [
          "ITMNP",
          "ITTNI"
      ]
  },
  {
      code: "HRSIB",
      name: "Sibenico",
      lat: 43.7249,
      lng: 15.8969,
      to: [
          "ITNUM",
          "ITTMI"
      ]
  },
  {
      code: "HRDBV",
      name: "Dubrovnik",
      lat: 42.6524,
      lng: 18.0939,
      to: [
          "ITOTN",
          "ITTMI",
          "ITTNI",
          "ALVOA",
          "HRSPU"
      ]
  },
  {
      code: "GROTH",
      name: "Othoni",
      lat: 39.84094,
      lng: 19.4042,
      to: [
          "ITBDS"
      ]
  },
  {
      code: "GRLEV",
      name: "Lefkada",
      lat: 38.83578,
      lng: 20.71847,
      to: [
          "ITOTO"
      ]
  },
  {
      code: "ITMNF",
      name: "Monfalcone",
      lat: 45.7884,
      lng: 13.543,
      to: [
          "ITRMI"
      ]
  }
]

  export default coords;