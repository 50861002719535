/*eslint-disable*/
import React from "react";

// reactstrap components
import { NavItem, NavLink, Nav, Container, Row, Col } from "reactstrap";

function Footer() {
  return (
    <>
      <Container fluid>
        <footer className="footer pt-0 pb-0 pl-1 pr-1">
        <Row className="align-items-center justify-content-lg-between">
            <Col>
            <Nav className="nav-footer align-items-center justify-content-center">
                  <NavItem>
                    <NavLink
                      href="https://www.cmcc.it/"
                      target="_blank"
                    >
                      <img
                        alt="Corila"
                        width="60"
                        src= {require("assets/img/partners/0_Corila.png").default}
                      />
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="http://www.csamarenostrum.hr/en/"
                      target="_blank"
                    >
                      <img
                        alt="Falco Tracciato"
                        width="35"
                        src= {require("assets/img/partners/1_FalcoTracciato.png").default}
                      />
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="http://www.csamarenostrum.hr/en/"
                      target="_blank"
                    >
                      <img
                        alt="ITL"
                        width="120"
                        src= {require("assets/img/partners/2_ITL.png").default}
                      />
                    </NavLink>
                  </NavItem>
                  <NavItem className="pt-3">
                    <NavLink
                      href="https://www.unizd.hr/"
                      target="_blank"
                    >
                      <img
                        alt="Asset"
                        width="70"
                        src= {require("assets/img/partners/3_Asset.png").default}
                      />
                    </NavLink>
                  </NavItem>
                  <NavItem className="pt-3">
                    <NavLink
                      href="https://mmpi.gov.hr"
                      target="_blank"
                    >
                      <img
                        alt="SVEM"
                        width="100"
                        src= {require("assets/img/partners/4_SVEM.png").default}
                      />
                    </NavLink>
                  </NavItem>
                  <NavItem  className="pt-3">
                    <NavLink
                      href="https://www.adspmam.it/"
                      target="_blank"
                    >
                      <img
                        alt="ARAP"
                        width="100"
                        src= {require("assets/img/partners/5_Arap.png").default}
                      />
                    </NavLink>
                  </NavItem>
                  <NavItem  className="pt-3">
                    <NavLink
                      href="https://www.adspmam.it/"
                      target="_blank"
                    >
                      <img
                        alt="TER-MOLI"
                        width="45"
                        src= {require("assets/img/partners/6_AZ.SOGG.TUR.png").default}
                      />
                    </NavLink>
                  </NavItem>
                  <NavItem  className="pt-3">
                    <NavLink
                      href="https://www.adspmam.it/"
                      target="_blank"
                    >
                      <img
                        alt="LUU"
                        width="60"
                        src= {require("assets/img/partners/7_LUUmagNo.png").default}
                      />
                    </NavLink>
                  </NavItem>
                  <NavItem  className="pt-3">
                    <NavLink
                      href="https://www.adspmam.it/"
                      target="_blank"
                    >
                      <img
                        alt="PGZ"
                        width="25"
                        src= {require("assets/img/partners/8_PGZ.png").default}
                      />
                    </NavLink>
                  </NavItem>
                  <NavItem  className="pt-3">
                    <NavLink
                      href="https://www.adspmam.it/"
                      target="_blank"
                    >
                      <img
                        alt="ZLU"
                        width="70"
                        src= {require("assets/img/partners/9_ZLU.png").default}
                      />
                    </NavLink>
                  </NavItem>
                  <NavItem  className="pt-3">
                    <NavLink
                      href="https://www.adspmam.it/"
                      target="_blank"
                    >
                      <img
                        alt="LUCKAUPRAVA"
                        width="65"
                        src= {require("assets/img/partners/10_LUCKAUPRAVA.png").default}
                      />
                    </NavLink>
                  </NavItem>
                  <NavItem  className="pt-3">
                    <NavLink
                      href="https://www.adspmam.it/"
                      target="_blank"
                    >
                      <img
                        alt="PFRI"
                        width="80"
                        src= {require("assets/img/partners/11_pfri.png").default}
                      />
                    </NavLink>
                  </NavItem>
                  <NavItem  className="pt-3">
                    <NavLink
                      href="https://www.adspmam.it/"
                      target="_blank"
                    >
                      <img
                        alt="LogoTeam"
                        width="80"
                        src= {require("assets/img/partners/12_Logoteam.png").default}
                      />
                    </NavLink>
                  </NavItem>
                  <NavItem  className="pt-3">
                    <NavLink
                      href="https://www.adspmam.it/"
                      target="_blank"
                    >
                      <img
                        alt="CMCC"
                        width="110"
                        src= {require("assets/img/partners/13_CMCC.png").default}
                      />
                    </NavLink>
                  </NavItem>
                  <NavItem  className="pt-3">
                    <NavLink
                      href="https://www.adspmam.it/"
                      target="_blank"
                    >
                      <img
                        alt="SENJ"
                        width="60"
                        src= {require("assets/img/partners/14_SENJ.png").default}
                      />
                    </NavLink>
                  </NavItem>
                  <NavItem  className="pt-3">
                    <NavLink
                      href="https://www.adspmam.it/"
                      target="_blank"
                    >
                      <img
                        alt="MMPI"
                        width="150"
                        src= {require("assets/img/partners/15_MMPI.png").default}
                      />
                    </NavLink>
                  </NavItem>
              </Nav>
            </Col>
          </Row>
          <Row className="align-items-center justify-content-lg-between">
            <Col>
              <div className="copyright text-center text-muted">
                {/* <p style={{fontSize: 14}}>
                This web application was funded by the European Regional Development Fund through the Italy-Croatia
                  Interreg programme, project GUTTA, grant number 10043587
                </p> */}
              </div>
            </Col>
          </Row>
        </footer>
      </Container>
    </>
  );
}

export default Footer;
