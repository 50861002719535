import React from "react";
import {FormGroup,Input,Row,Col} from "reactstrap";
import {engineOptions} from "static/sidebarOptions.js"

const DVE = React.memo((props) => {
    const { selectedDVP, selectedDVT, selectedDVE, changeSelectOptionHandler } = props;
    //console.log("DVE props: ",props);
    
    var options;
    var disableValue = true;

    if (!(selectedDVP.fromValue === "WEST" || selectedDVP.fromValue === "EAST" || selectedDVT === "ALL")) {
        disableValue = false;
        options = engineOptions.map((el) => <option key={el} value={el}>{el}</option>);        
    }

    if(!selectedDVP.toValue || selectedDVP.toValue === "to" || !selectedDVT || selectedDVT === "st")
        disableValue = true;

    return (
        <Row className="row pl-5 pt-3">
            <Col md="10" xs="9" className="mr-0 ml-0 pl-0 pr-0">
                <FormGroup>
                    <Col>
                        {<Input id="exampleFormControlSelect" type="select" disabled={disableValue} value={selectedDVE} onChange={changeSelectOptionHandler}>
                            {options}
                        </Input>}
                    </Col>
                </FormGroup>
            </Col>
        </Row>
    );

});

export default DVE;
