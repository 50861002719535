import Waypoint from 'variables/Map/Waypoint';

let wmsUrl = "https://ws.cmcc-opa.eu/frame-visir/ws/";

//To read production dates from solution.json
function getLandingSolutionsJson() {
  const controller = new AbortController();
  const timeoutId = setTimeout(() => {
    controller.abort();
  }, 8000);
  return fetch(wmsUrl + '?product=getLandingInfo',{ signal: controller.signal })
    .then((response) => response.json())
    .then((responseJson) => {
      //console.log(responseJson[0][0]);
      clearTimeout(timeoutId);
      return responseJson[0];
    })
    .catch((error) => {
      if (error.name === 'AbortError') {
        alert('Something went wrong. Please try selecting different ports or try again later.');
      }
      console.error("error in getLandingSolutionsJson fetch->", error);
      //alert("Failed to load forecast dates. Please try again later!");
    });
};

//to plot scatterplot for sailboat (DS)
function getScatterSBFile(path) {
  const controller = new AbortController();
  const timeoutId = setTimeout(() => {
    controller.abort();
  }, 8000);
  return fetch(wmsUrl + '?path=' + path + '&product=s', { signal: controller.signal })
    .then((response) => response.json())
    .then((responseJson) => {
      //[0] -> same dir, [1] -> reverse
      //console.log("ScatterSB REQUEST: ",responseJson);
      clearTimeout(timeoutId);
      return responseJson;
    })
    .catch((error) => {
      if (error.name === 'AbortError') {
        alert('Something went wrong. Please try selecting different ports or try again later.');
      }
      console.error("error in getSolutionsJsonFile fetch->", error);
      //alert("Failed to load global route data. Please try again later!");
    });
};

//To read Solutions[MB/SB] files
function getSolutionsJsonFile(path, vessel) {
  const controller = new AbortController();
  const timeoutId = setTimeout(() => {
    controller.abort();
  }, 8000)
  return fetch(wmsUrl + '?path=' + path + '&product=e&vessel='+ vessel, { signal: controller.signal })
  //return fetch("https://ws-test.cmcc-opa.eu/frame-visir/ws/?path=HRLPR_ITGMA&product=e")
    .then((response) => response.json())
    .then((responseJson) => {
      //[0] -> same dir, [1] -> reverse
      //console.log("SOLUTION RESPONSE: ",responseJson);
      clearTimeout(timeoutId);
      return responseJson;
    })
    .catch((error) => {
      if (error.name === 'AbortError') {
        alert('Something went wrong. Please try selecting different ports or try again later.');
      }
      //console.error("error in getSolutionsJsonFile fetch->", error);
      //alert("Failed to load global route data. Please try again later!");
    });
};

//To read voyageplan json files
function getJsonRoute(path, vessel, startDate, startTime, engine, stype) {
  const controller = new AbortController();
  const timeoutId = setTimeout(() => {
    controller.abort();
  }, 8000);
  let routesArray = [];
  return fetch(wmsUrl + '?product=a&path=' + path + '&startDate=' + startDate
    + '&startTime=' + startTime + '&vessel=' +vessel+ '&engine=' + engine + '&sbType=' +stype , { signal: controller.signal })
  //return fetch("https://ws-test.cmcc-opa.eu/frame-visir/ws/?path=HRZAD_HRPUY&startDate=20230329&startTime=0800&engine=085&product=a&vessel=M")
    .then((response) => response.json())
    .then((responseJson) => {
       //console.log("VOYAGEPLAN RESPONSE: ",responseJson);
       //[0] -> name, [1] -> co2/time, [2] -> dist
      for (var i = 1; i < responseJson.length; i++) {
        let routeArray = [];
        let waypointsArray = [];
        let currentRoute = [];
        responseJson[i].forEach((item, key) => {
          var waypoint = <Waypoint CO2rate={item.CO2rate} COG={item.COG} HDG={item.HDG} Hs={item.Hs} ISO_date={item.ISO_date}
            SOG={item.SOG} Th_wave={item.Th_wave} cumCO2={item.cumCO2} cumDist={item.cumDist}
            cumTime={item.cumTime} lat={item.lat} lon={item.lon} node_ID={item.node_ID}
            w_cross={item.w_cross} w_parallel={item.w_parallel} waypoint_ID={item.waypoint_ID} 
            wind_dir={item.wind_dir} wind_magn={item.wind_magn}
            engine={item.engine} sailboat={item.sailboat} />
          waypointsArray.push(waypoint);
          routeArray.push([Math.round(item.lat * 100000) / 100000, Math.round(item.lon * 100000) / 100000]);
          currentRoute = [waypointsArray, routeArray];
        })
        routesArray.push(currentRoute);
      }
      routesArray.push(responseJson[0]);
      //console.log(routesArray);
      clearTimeout(timeoutId);
      return routesArray;
    })
    .catch((error) => {
      if (error.name === 'AbortError') {
        alert('Something went wrong. Please try selecting different ports or try again later.');
      }
      //console.error("error in getJsonRoute fetch->", error);
      //alert("Failed to load local route data. Please try again later!");
    });
}

//To get all(date + engine/stype) co2/time bundles
function getDVTAllBundles(path, product, vessel) {
  const controller = new AbortController();
  const timeoutId = setTimeout(() => {
    controller.abort();
  }, 8000);
  let routesArray = [];
  return fetch(wmsUrl + '?path=' + path + '&product=' + product + '&vessel='+vessel, { signal: controller.signal })
    .then((response) => response.json())
    .then((responseJson) => {
      // console.log(responseJson);
      for (var i = 0; i < responseJson.length; i++) {
        let routeArray = [];
        let waypointsArray = [];
        let currentRoute = [];
        responseJson[i].forEach((item, key) => {
          var waypoint = <Waypoint CO2rate={item.CO2rate} COG={item.COG} HDG={item.HDG} Hs={item.Hs} ISO_date={item.ISO_date}
            SOG={item.SOG} Th_wave={item.Th_wave} cumCO2={item.cumCO2} cumDist={item.cumDist}
            cumTime={item.cumTime} lat={item.lat} lon={item.lon} node_ID={item.node_ID}
            w_cross={item.w_cross} w_parallel={item.w_parallel} waypoint_ID={item.waypoint_ID} 
            wind_dir={item.wind_dir} wind_magn={item.wind_magn}
            engine={item.engine} sailboat={item.sailboat} />
          waypointsArray.push(waypoint);
          routeArray.push([Math.round(item.lat * 100000) / 100000, Math.round(item.lon * 100000) / 100000]);
          currentRoute = [waypointsArray, routeArray];
        })
        routesArray.push(currentRoute);
      }
      //routesArray.push(responseJson[0]);
      clearTimeout(timeoutId);
      return routesArray;
    })
    .catch((error) => {
      if (error.name === 'AbortError') {
        alert('Something went wrong. Please try selecting different ports or try again later.');
      }
      console.error("error in getJsonRoute fetch->", error);
      //alert("Failed to load local route data. Please try again later!");
    });
}

function getEastWestBundles(product, leastSelection) {
  const controller = new AbortController();
  const timeoutId = setTimeout(() => {
    controller.abort();
  }, 8000);
  let routesArray = [];
  return fetch(wmsUrl + '?product=' + product + "&leastSelection="+leastSelection, { signal: controller.signal })
    .then((response) => response.json())
    .then((responseJson) => {
      //console.log(responseJson);
      for (var i = 0; i < responseJson.length; i++) {
        for (var j = 1; j < responseJson[i].length; j++) {
          let routeArray = [];
          let waypointsArray = [];
          let currentRoute = [];
          // eslint-disable-next-line no-loop-func
          responseJson[i][j].forEach((item) => {
            var waypoint = <Waypoint CO2rate={item.CO2rate} COG={item.COG} HDG={item.HDG} Hs={item.Hs} ISO_date={item.ISO_date}
              SOG={item.SOG} Th_wave={item.Th_wave} cumCO2={item.cumCO2} cumDist={item.cumDist}
              cumTime={item.cumTime} lat={item.lat} lon={item.lon} node_ID={item.node_ID}
              w_cross={item.w_cross} w_parallel={item.w_parallel} waypoint_ID={item.waypoint_ID} 
              wind_dir={item.wind_dir} wind_magn={item.wind_magn}
              engine={item.engine} sailboat={item.sailboat} />
            waypointsArray.push(waypoint);
            routeArray.push([Math.round(item.lat * 100000) / 100000, Math.round(item.lon * 100000) / 100000]);
            currentRoute = [waypointsArray, routeArray, responseJson[i][0]];
          })
          routesArray.push(currentRoute);
        }
      }
      //console.log(routesArray);
      clearTimeout(timeoutId);
      return routesArray;
    })
    .catch((error) => {
      if (error.name === 'AbortError') {
        alert('Something went wrong. Please try selecting different ports or try again later.');
      }
      console.error("error in getJsonRoute fetch->", error);
      //alert("Failed to load local route data. Please try again later!");
    });
}

function getExportFile(path, date, time, engine, stype, format, vessel) {
  const controller = new AbortController();
  const timeoutId = setTimeout(() => {
    controller.abort();
  }, 8000);
  return fetch(wmsUrl + '?product=downloadRoute&path=' + path + '&startDate=' + date + '&startTime=' + time + '&engine=' + engine + '&sbType=' +stype + '&format=' + format+ '&vessel=' +vessel, { signal: controller.signal })
    .then((response) => {
      if (response.ok === true) {
        //console.log(response.url);
        clearTimeout(timeoutId);
        return response.url;
      }
    })
    .catch((error) => {
      if (error.name === 'AbortError') {
        alert('Something went wrong. Please try selecting different ports or try again later.');
      }
      console.error("error in getLandingSolutionsJson fetch->", error);
      //alert("Failed to export files. Please try again later!");
    });
};

export { getScatterSBFile, getSolutionsJsonFile, getJsonRoute, getDVTAllBundles, getEastWestBundles, getLandingSolutionsJson, getExportFile }