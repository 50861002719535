
import React from "react";
import { Card, CardBody, Col } from "reactstrap";
// react library for routing
import { useLocation} from "react-router-dom";
import Header from "components/Header.js";
import {getLandingSolutionsJson} from "static/readData"


function ServiceNotAvailable() {
  React.useEffect(()=> {
    getLandingSolutionsJson().then(res => {
      if(res)
        window.location = "/";
    }).catch(function (error) {
        //console.log(error);
    });
  },[]);
  
  const mainContentRef = React.useRef(null);
  const location = useLocation();
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContentRef.current.scrollTop = 0;
  }, [location]);

  return (
    <>
      <div className="main-content" ref={mainContentRef}>
        <Header/>
        <h1 className="text-white text-center text-lg-left display-3 header header-dark bg-info pb-6 pl-lg-8">
        </h1>
        <Col className="pb-1 mt--5 pl-lg-8 pr-lg-8">
          <Card>
            <CardBody className="text-justify">
              <h1>FRAME-VISIR is temporarily unavailable</h1>
              <br/>
              <h4>Our team is currently investigating the issue and working to get everything back up and running as soon as possible.<br/>
                In the meantime, you can still access the About, Help, Feedback, and News sections on this page. </h4>
              <br/>
              <h4>We apologize for any inconvenience this may cause.</h4>
            </CardBody>
          </Card>
        </Col>
      </div>
    </>
  );
}

export default ServiceNotAvailable;