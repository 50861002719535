
import React from "react";
// react library for routing
import { Link } from "react-router-dom";
// reactstrap components
import {
  UncontrolledCollapse,
  //NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";
import PropTypes from "prop-types";
import classnames from "classnames";

const Header = React.memo(({ sidenavOpen, toggleSidenav, titleText }) => {
  
  // const ShowNavBrand = () => {
  //   if (window.innerWidth < 1200) {
  //     return (<NavbarBrand to="/" tag={Link}>
  //       <img
  //         alt="..."
  //         src={require("assets/img/brand/logo-white.png").default}
  //       />
  //     </NavbarBrand>);
  //   }
  //   else {
  //     return (<></>);
  //   }
  // };
  const ShowTitle = () => {
    if (window.innerWidth > 1200) {
      return (<h2 className="text-white pt-2">{titleText}</h2>);
    }
    else {
      return (<h4 className="text-white text-center pt-2"> </h4>);
    }
  };
  return (
    <>
      <Navbar
        className="navbar-horizontal navbar-main navbar-dark bg-info p-1"
        expand="lg"
        id="navbar-main"
      >
        <Container fluid>
          {/* Toggler to open sidebar in mobile devices */}
          <Nav>
            <NavItem className="d-xl-none pl-4">
              <div
                className={classnames(
                  "pr-3 sidenav-toggler",
                  { active: sidenavOpen },
                )}
                onClick={toggleSidenav}
              >
                <div className="sidenav-toggler-inner">
                  <i className="sidenav-toggler-line" />
                  <i className="sidenav-toggler-line" />
                  <i className="sidenav-toggler-line" />
                </div>
              </div>
            </NavItem>
          </Nav >
          {/* Show brand logo in header only in mobile device */}
          {/*<ShowNavBrand />*/}
          <ShowTitle className="d-sm-none"/>
          {/* Navbar toggler for top menu in header in mobile devices */}
          <button
            aria-controls="navbar-collapse"
            aria-expanded={false}
            aria-label="Toggle navigation"
            className="navbar-toggler"
            data-target="#navbar-collapse"
            data-toggle="collapse"
            id="navbar-collapse"
            type="button"
          >
            <span style={{ fontSize: '30px' }}>...</span>
            {/* <span className="bars" /> */}
          </button>
          {/* The drop down for navbar toggler showing top menu options */}
          <UncontrolledCollapse
            className="navbar-custom-collapse"
            navbar
            toggler="#navbar-collapse"
          >
            <div className="navbar-collapse-header">
              <Row>
                {/* Brand logo in drop down menu */}
                <Col className="collapse-brand" xs="6">
                  <Link to="/admin/dashboard">
                    <img
                      alt="..."
                      src={require("assets/img/brand/logo-grey.png").default}
                      style={{width: "150px", height: "58px", float: "center"}}
                    />
                  </Link>
                </Col>
                {/* Cross icon to close drop down menu */}
                <Col className="collapse-close" xs="6">
                  <button
                    aria-controls="navbar-collapse"
                    aria-expanded={false}
                    aria-label="Toggle navigation"
                    className="navbar-toggler"
                    data-target="#navbar-collapse"
                    data-toggle="collapse"
                    id="navbar-collapse"
                    type="button"
                  >
                    <span />
                    <span />
                  </button>
                </Col>
              </Row>
            </div>
            {/* All the menu options */}
            <Nav className="ml-auto" navbar>
              <NavItem>
                <NavLink to="/" tag={Link}>
                  <span className="nav-link-inner--text">Home</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/other/about" tag={Link}>
                  <span className="nav-link-inner--text">About</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/other/help" tag={Link}>
                  <span className="nav-link-inner--text">Help</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/other/feedback" tag={Link}>
                  <span className="nav-link-inner--text">Feedback</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/other/news" tag={Link}>
                  <span className="nav-link-inner--text">News</span>
                </NavLink>
              </NavItem>
            </Nav>
          </UncontrolledCollapse>
        </Container>
      </Navbar>
    </>
  );
});

Header.defaultProps = {
  toggleSidenav: () => { },
  sidenavOpen: false,
};
Header.propTypes = {
  toggleSidenav: PropTypes.func,
  sidenavOpen: PropTypes.bool,
};

export default Header;
