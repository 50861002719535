// react plugin used to create charts
import React from "react"
import { Scatter } from "react-chartjs-2";
import {vesselNamesMB} from "static/sidebarOptions.js";

function getMaxX(arr) {
  let maxX = 0;
  arr.forEach((el) => {
    if (el.x > maxX) maxX = el.x;
  });
  return parseInt(maxX) + 1;
}

function getMaxY(arr) {
  let maxY = 0;
  arr.forEach((el) => {
    if (el.y > maxY) maxY = el.y;
  });
  return parseInt(maxY) + 1;
}

function getMaxL(arr) {
  let maxL = 0;
  arr.forEach((el) => {
    if (el.l > maxL) maxL = el.l;
  });
  return parseFloat(maxL);
}

function getLengthData(arr) {
  //The maximum value of path lengthening color bar is stored in this variable
  const maxLength = getMaxL(arr);
  //console.log("Max L value: ", maxLength);

  //THIS WAS THE REASON OF INFINTE LOOP : deltaL values were so small that the for loop never proceeded since lengthTickStep was 0, removed toFixed() function
  //const lengthTickStep = parseFloat((maxLength / 8).toFixed(2));

  //The stepsize of the ticks in the color bar is calculated and stored
  //It is divided by 8, taking into consideration that the maximum ticks 
  //for the colorbar will be 10(8 + 1 [minLength = 0] + 1 [maxLength])
  const lengthTickStep = parseFloat(maxLength / 8);
  //console.log("Tickstep: ", lengthTickStep);

  //dataLengthY stores all the tick values of lengthening colorbar
  const dataLengthY = [];
  for (var dataL = lengthTickStep; dataL <= maxLength + 0.01; dataL += lengthTickStep) {
    if (!(dataLengthY.length === 7))
      dataLengthY.push(parseFloat(dataL));
  }

  if (!(dataLengthY.length === 8))
    dataLengthY.push(parseFloat((maxLength)));

  return dataLengthY;
};

function getColorBars(arr) {

  const dataLengthY = getLengthData(arr);
  // console.log("L values: ", dataLengthY);

  //To draw the legthening colorbar in the right side of the chart
  const colors = ["darkred", "red", "orange", "yellow", "green", "lightgreen", "lightblue", "blue", "darkblue", "black"];
  const colorBars = dataLengthY.map((el, i) => { return ({ y: el, color: colors[i] }) });
  // console.log("Colorbars: ", colorBars);

  return colorBars;
};

function getMarkerColorFromLValue(ele, arr) {
  var markerColor, preY = 0;
  const colorBars = getColorBars(arr);
  if (ele === 0)
    markerColor = "darkred";
  colorBars.forEach((el) => {
    if (ele > preY && ele <= el.y)
      markerColor = el.color;
    preY = el.y
  });
  return markerColor;
};

function getPlugins(arr, DVP, time, diaId, diag) {
  
  if (arr.length !== 0 && !(time === 'st')) {
    const colorBars = getColorBars(arr);
    const drawColorBarAndLegend = [
      {
        beforeDraw: chart => {
          var ctx = chart.chart.ctx;
          var xAxis = chart.scales["CO2"];
          var yAxis = chart.scales["L"];
          ctx.save();
          if(!(diaId === "notALL" && diag === "right")){
            var prevY = 0;         
            colorBars.forEach((c, i) => {
              var yBottom = yAxis.getPixelForValue(prevY);
              var yTop = yAxis.getPixelForValue(c.y);
              var grd = ctx.createLinearGradient(xAxis.right + 4, yBottom, xAxis.right + 4, yTop);
              grd.addColorStop(0, colorBars[i].color);
              if (i === colorBars.length - 1)
                grd.addColorStop(1, "black");
              else
                grd.addColorStop(1, colorBars[i + 1].color);
              ctx.fillStyle = grd;
              ctx.fillRect(xAxis.right + 4, yTop, 10, yBottom - yTop);
              prevY = c.y;
            });
          }
          // To make the engine load legend at the bottom of the chart
          ctx.font = '11px Arial';
          ctx.strokeStyle = 'lightgray';
          ctx.fillStyle = 'black';
          ctx.beginPath();
          ctx.strokeRect(xAxis.left - 20, yAxis.bottom + 50, 200, 19)
          ctx.fillText('Engine Load:', xAxis.left - 15, yAxis.bottom + 65);
          ctx.arc(xAxis.left + 55, yAxis.bottom + 60, 2, 0, 2 * Math.PI, false);
          ctx.fillText('70%', xAxis.left + 60, yAxis.bottom + 65);
          ctx.arc(xAxis.left + 95, yAxis.bottom + 60, 3, 0, 2 * Math.PI, false);
          ctx.fillText('85%', xAxis.left + 102, yAxis.bottom + 65);
          ctx.arc(xAxis.left + 140, yAxis.bottom + 60, 4, 0, 2 * Math.PI, false);
          ctx.fillText('100%', xAxis.left + 147, yAxis.bottom + 65);
          ctx.fill();
          ctx.restore();
        }
      }
    ];
    return drawColorBarAndLegend;
  }
  else if((!(time === 'st') || (!(DVP.includes("to")) && arr.length === 0)) && (!(DVP.includes("WEST") || DVP.includes("EAST")))) {
    const loading = [
      {
        beforeDraw: chart => {
          var ctx = chart.chart.ctx;
          ctx.fillText('Loading...', 112, 145);
        }
      }];
    return loading;
  }
};

function getData(arr, dateTime, power, diaId) {

  const markersDataset = [], dashLineData = [];
  const time = dateTime.split("_")[1];

  var radius, style, lengthColor, borderColor;
  arr.forEach((el, i) => {
    var engineLoadRadius = (el.e === vesselNamesMB[0]) ? 2 : ((el.e === vesselNamesMB[1]) ? 3 : 4);
    var engineLoad = (el.e === vesselNamesMB[0]) ? "70" : ((el.e === vesselNamesMB[1]) ? "85" : "100");

    //Set length colors    
    if (time === "ALL" || diaId === "ALL" || (time !== "ALL" && el.diag === "left" && el.dt === dateTime && engineLoad === power))
      lengthColor = getMarkerColorFromLValue(el.l, arr);
    else
      lengthColor = "#a9a9a9";
    if (!lengthColor)
      lengthColor = "black";
    
    //Set engine load radius
    if (!(el.diag === "right") && el.dt === dateTime && engineLoad === power && diaId !== "notALL") {
      style = 'triangle';
      radius = engineLoadRadius + 8;
      borderColor = "black"
    }
    else {
      style = 'circle';
      radius = engineLoadRadius;
      borderColor = lengthColor;
    }

    //To plot all the markers
    markersDataset.push({
      yAxisID: "CII",
      label: i,
      lValue: parseFloat(el.l.toFixed(2)),
      diaId: el.diag,
      depDateTime: el.dt,
      power: engineLoad,
      pointStyle: style,
      pointBorderColor: borderColor,
      pointBackgroundColor: lengthColor,
      pointRadius: radius,
      pointHoverRadius: radius + 2,
      data: [{ x: el.x, y: el.y }]
    })
  });

  //To plot the equality line
  arr.sort((a, b) => { return a.x - b.x });
  arr.forEach((el, i) => {
    dashLineData.push({ x: el.x, y: el.x + el.l })
  });

  //To plot the one-one dotted line
  var maxX = getMaxX(arr);
  var maxY = getMaxY(arr);
  var minXY = Math.min(maxX, maxY);
  const dottedLineData = [];
  for (var i = 0; i <= minXY; i++) {
    if (arr.length !== 0)
      dottedLineData.push({ x: i, y: i })
  }
  if (maxX === 0.5)
    dottedLineData.push({ x: 0.5, y: 0.5 })

  //Pushing the dashline (equality equation) and dottedline(one-one line) to the dataset array
  markersDataset.push({
    yAxisID: "CII",
    label: "dashed",
    type: "scatter",
    fill: false,
    borderDash: [3, 3],
    borderWidth: 1,
    pointRadius: 0,
    pointHitRadius: 0,
    showLine: true,
    backgroundColor: "black",
    borderColor: "black",
    data: dashLineData
  },
    {
      yAxisID: "CII",
      label: "dotted",
      type: "line",
      fill: false,
      borderDash: [1, 1],
      borderWidth: 1,
      pointRadius: 0,
      pointHitRadius: 0,
      backgroundColor: "black",
      borderColor: "black",
      data: dottedLineData,
    },
    {
      yAxisID: "L",
      label: "colorbar",
      data: getLengthData(arr)
    });

  return markersDataset;
};

function getOptions() {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: 0,
    },
    legend: {
      display: false
    },
    title: {
      display: true,
      padding: 1,
    },
    scales: {
      xAxes: [{
        id: "CO2",
        scaleLabel: {
          display: true,
          labelString: "- \u0394CO\u2082 [%]",
        }
      }],
      yAxes: [{
        id: "CII",
        scaleLabel: {
          display: true,
          labelString: "- \u0394CII [%]",
        }
      }]
    }
  }
  return options;
};

function getUpdatedOptions(arr, titleText, diaId, diag) {
  var displayLValues;
  if(diaId === "notALL" && diag === "right")
    displayLValues = false;
  else
    displayLValues = true;
  const options = {
    ...getOptions(),
    title: {
      display: true,
      text: titleText,
      padding: 1,
    },
    tooltips: {
      enable: true,
      intersect: false,
      mode: "point",
      titleSpacing: 4,
      titleMarginBottom:1,
      footerSpacing:4,
      footerMarginTop:1,
      callbacks: {
        beforeTitle: (item, data) => data.datasets[item[0].datasetIndex].depDateTime,
        afterTitle: (item, data) => 'Load :  ' + data.datasets[item[0].datasetIndex].power + '%',
        afterBody: (item, data) => '----------------------',
        label: (tooltipitems, data) => '',
        beforeFooter: (item, data) => ' \u0394L :       ' + data.datasets[item[0].datasetIndex].lValue + "%",
        footer: (item, data) => '-\u0394CO\u2082 :  ' + parseFloat(data.datasets[item[0].datasetIndex].data[0].x.toFixed(2)) + '%',
        afterFooter: (item, data) => '-\u0394CII :    ' + parseFloat(data.datasets[item[0].datasetIndex].data[0].y.toFixed(2)) + '%',
      }
    },
    onHover: (event, chartElement) => {
      if (diaId !== "notALL" && arr[0].diag === "left")
        event.target.style.cursor = (chartElement[0]) ? 'pointer' : 'default';
    },
    scales: {
      xAxes: [{
        id: "CO2",
        scaleLabel: {
          display: true,
          labelString: "- \u0394CO\u2082 [%]",
          padding: {
            top: 0,
            bottom: 25
          }
        },
        ticks: {
          beginAtZero: true,
          max: getMaxX(arr),
        },
      }],
      yAxes: [{
        id: "CII",
        position: "left",
        scaleLabel: {
          display: true,
          labelString: "- \u0394CII [%]",
          padding: 0
        },
        ticks: {
          beginAtZero: true,
          max: getMaxY(arr)
        },
      },
      {
        id: "L",
        display: displayLValues,
        position: "right",
        reverse: true,
        ticks: {
          beginAtZero: true,
          max: getLengthData(arr)[7],
          stepSize: parseFloat((getMaxL(arr) / 8).toFixed(2)),
          padding: 5,
        },
        afterBuildTicks: function (scale, ticks) {
          scale.ticks.shift();
          //console.log(getLengthData(arr))
          //console.log(ticks);
          return;
        },
        gridLines: {
          display: false
        },
        scaleLabel: {
          display: true,
          labelString: "\u0394L [%]",
          padding: {
            bottom: 4
          }
        }
      }]
    },
  };
  return options;
}

const DiagramDD = React.memo((props) => {
  //console.log("DD props: ", props);

  const { port, date, time, engineLoad, diaId, diagramRef, solutions } = props;

  var fromDVP, toDVP, diag, solArrName;
  if (port.sFromValue && port.sToValue) {
    fromDVP = port.sFromValue;
    toDVP = port.sToValue;
    diag = "right";
    solArrName = "revSolArr";
  }
  else {
    fromDVP = port.fromValue;
    toDVP = port.toValue;
    diag = "left";
    solArrName = "solArr";
  }
  var DVP = fromDVP + "_" + toDVP;
  var depDateTime = date + "_" + time;

  var data = { datasets: [] }, arr = [];
  var options = getOptions();

  if (!(DVP.includes("to")) && !(time === 'st') && !(DVP.includes("WEST")) && !(DVP.includes("EAST")) &&
  solutions && (solutions[solArrName][0].Departure === fromDVP && solutions[solArrName][0].Arrival === toDVP)) {

    const titleText = fromDVP + "_" + toDVP;

    solutions[solArrName].forEach((el) => {
      if (el.Type === "CO2t") {
        arr.push({ x: Math.abs(parseFloat(el.dCO2t)), y: Math.abs(parseFloat(el.dCII)), l: Math.abs(parseFloat(el.dDistance)), e: el.Vessel, dt: el.depDateTime, diag: diag })
      }
    });

    if (arr.length !== 0) {
      arr.forEach(function (el, i) {
        var load = (el.e ===vesselNamesMB[0]) ? "70" : ((el.e === vesselNamesMB[1]) ? "85" : "100");
        if (el.dt === depDateTime && load === engineLoad) {
          arr.splice(i, 1);
          arr.unshift(el);
        }
      });
      data = { datasets: getData(arr, depDateTime, engineLoad, diaId) };
      options = getUpdatedOptions(arr, titleText, diaId, diag);
    }
  }
  return (<Scatter options={options} plugins={getPlugins(arr, DVP, time, diaId, diag)} data={data} className="chart-canvas" ref={diagramRef} redraw />)
});

export default DiagramDD;