
import React from "react";
// react library for routing
import {Switch, Route, Redirect} from "react-router-dom";
// core components
import Header from "components/Header.js";
import Footer from "components/Footer/OtherFooter.js";
import Help from "pages/Help.js";
import Contact from "pages/Contact/Feedback.js";
import About from "pages/About.js";
import News from "pages/News.js";
//import Dashboard from "pages/Dashboard";


function OtherLayout() {
  const mainContentRef = React.useRef(null);
  // const location = useLocation();
  // React.useEffect(() => {
  //   document.documentElement.scrollTop = 0;
  //   document.scrollingElement.scrollTop = 0;
  //   mainContentRef.current.scrollTop = 0;
  // }, [location]);

  return (
    <>
      <div className="main-content" ref={mainContentRef}>
        <Header />
        <Switch>
          <Route path="/other/help" component ={Help} />
          <Route path="/other/news" component = {News} />
          <Route path="/other/about" component = {About} />
          <Route path="/other/feedback" component = {Contact} />
          <Redirect from="*" to="/" />
        </Switch>
        <Footer />
      </div>
    </>
  );
}

export default OtherLayout;