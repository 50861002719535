import React from "react";
// reactstrap components
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
} from "reactstrap";
import {getLandingSolutionsJson} from "static/readData"
import {DIoptions} from "static/divOptions.js"

const BDateInfoDI = React.memo((props) => {
  //console.log("DI props: ", props);

  const { vessel } = props;
  const  [state, setState] = React.useState({});
  React.useEffect(()=> {
    getLandingSolutionsJson().then(res => {
      if(res)
        setState({res: res})
      else
        window.location = "/unavailable";
    }).catch(function (error) {
        //console.log(error);
    });
  },[]);

  var prodDates = DIoptions[0];
  if(vessel === "S")
    prodDates = DIoptions[1];
  
  if(state.res){
      const el = state.res;
      prodDates.forEach(prodDate => {
        prodDate.value = el[prodDate.jsonId].split(" ")[0]+", "+el[prodDate.jsonId].split(" ")[1]+" UTC"; 
      });
  }

  return (
    <>
      <div className="header pb-1" >
        <Container fluid>
          <div className="header-body">
            <Row className="justify-content-center">
              {prodDates?.map(prodDate =>
                <Col xl="3">
                  <Card className=" mb-3 mb-xl-0 ml-xl--2 mr-xl--2">
                    <CardBody className="pt-2 pb-2 pl-0 pr-0 ml-xl-0 text-center">
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className=" text-muted mb-0">
                            {prodDate?.displayName}
                          </CardTitle>
                          <span className="h4 font-weight-bold mb-0">{prodDate?.value}</span>
                        </div>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              )}
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
});

export default BDateInfoDI;
