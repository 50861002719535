import React from "react";
// reactstrap components
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  UncontrolledTooltip
} from "reactstrap";
import {DKoptions} from "static/divOptions.js"

const KeyMetricsDK = React.memo((props) => {
  //console.log("DK props: ", props);
  const { port, date, time, engineLoad, vesselName, solutions } = props;
  
  //const vesselName = (engineLoad === "70") ? vesselNamesMB[0] : ((engineLoad === "85") ? vesselNamesMB[1] : vesselNamesMB[2]);
  //var length = "...", duration = "...", co2t = "...", dDistance = "...", dDuration = "...", waveMagnAvg = "...", dCO2 = "...", dCII = "...";
  var metrics, routeName = port.fromValue + "_" + port.toValue + "/" + date + "_" + time + "/" + vesselName;
  
  //Sailboat
  if(!(vesselName.includes("Flybridge"))){
    routeName += "/time_";
    metrics = DKoptions[1];
  }
  //Motorboat
  else{
    routeName += "/CO2t_";
    metrics = DKoptions[0];
  }
  if(time==="ALL" || time==="st")
    metrics.forEach(metric => {metric.value = "..."});
  
  //Populate values in metrics
  if (solutions) {
    solutions.solArr.forEach((el) => {
      if (el.Name === routeName + el.Forcing) {
        metrics.forEach(metric => {
          // Prune decimal value for numbers
          if(metric.displayName.includes("routes")) 
            metric.value =  Math.abs(el[metric.jsonId]); 
          else 
            metric.value = parseFloat(Math.abs(el[metric.jsonId])).toFixed(1)});
      }
      // if(time==="ALL" || time==="st")
      //   metrics.forEach(metric => {metric.value = "..."});
    });
  }

  return (
    <>
      <div className="header pb-6 mb-3 mt-3" >
        <Container fluid>
          <div className="header-body">             
            <Row>
              {metrics?.map(metric => 
                <Col lg="3" xs="6" xl>
                  <Card className=" mb-3 mb-xl-0 mr-xl--2">
                    <CardBody className="pt-2 pb-2 pl-0 pr-0 text-center">
                      <Row>
                        <div className="col">
                          <CardTitle
                          //  tag="h5"
                            id= {metric?.id}
                            style={{fontSize:12, fontWeight:"600"}}
                            className="text-muted mb-0 mr">
                            {metric?.displayName}
                            <UncontrolledTooltip delay={0} target={metric?.id}>
                              <small>{metric?.info}</small>
                            </UncontrolledTooltip>
                          </CardTitle>
                          <span className="h3 font-weight-bold mb-0">
                            {metric?.value}
                          </span>
                        </div>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              )}
            </Row>

            {/* <Row>
              // Length 
              <Col lg="3" xs="6" xl>
                <Card className=" mb-3 mb-xl-0 mr-xl--2">
                  <CardBody className="pt-2 pb-2 pl-0 pr-0 text-center">
                    <Row>
                      <div className="col">
                        <CardTitle
                         //  tag="h5"
                          id= "dk_length"
                          style={{fontSize:12, fontWeight:"600"}}
                          className="text-muted mb-0 mr">
                          Length [nmi]
                          <UncontrolledTooltip delay={0} target="dk_length">
                            <small>Length of the least-CO<sub>2</sub> route</small>
                          </UncontrolledTooltip>
                        </CardTitle>
                        <span className="h3 font-weight-bold mb-0">
                          {length}
                        </span>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              // Duration
              <Col lg="3" xs="6" xl>
                <Card className=" mb-3 mb-xl-0 ml-xl--2 mr-xl--2">
                  <CardBody className="pt-2 pb-2 pl-0 pr-0 ml-xl-0 text-center">
                    <Row>
                      <div className="col">
                        <CardTitle
                         //  tag="h5"
                          id = "dk_duration"
                          style={{fontSize:12, fontWeight:"600"}}
                          className=" text-muted mb-0">
                          Duration [hr]
                          <UncontrolledTooltip delay={0} target="dk_duration">
                            <small>Duration of the least-CO<sub>2</sub> route</small>
                          </UncontrolledTooltip>
                        </CardTitle>
                        <span className="h3 font-weight-bold mb-0">{duration}</span>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              // CO2 emission 
              <Col lg="3" xs="6" xl>
                <Card className="card-stats mb-3 mb-xl-0 ml-xl--2 mr-xl--2">
                  <CardBody className="pt-2 pb-2 pl-0 pr-0 text-center">
                    <Row>
                      <div className="col">
                        <CardTitle
                         //  tag="h5"
                          id = "dk_co2emission"
                          style={{fontSize:12, fontWeight:"600"}}
                          className=" text-muted mb-0">
                          CO<sub>2</sub> emission [t]
                          <UncontrolledTooltip delay={0} target="dk_co2emission">
                            <small>CO<sub>2</sub> emission of the least-CO<sub>2</sub> route</small>
                          </UncontrolledTooltip>
                        </CardTitle>
                        <span className="h3 font-weight-bold mb-0">{co2t}</span>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              //CG Distance 
              <Col lg="3" xs="6" xl>
                <Card className="card-stats mb-3 mb-xl-0 ml-xl--2 mr-xl--2">
                  <CardBody className="pt-2 pb-2 pl-0 pr-0 text-center">
                    <Row>
                      <div className="col">
                        <CardTitle
                          //tag="h5"
                          id="dk_cgdist"
                          style={{fontSize:12, fontWeight:"600"}}
                          className="text-muted mb-0">
                          Distance Savings [%]
                          <UncontrolledTooltip delay={0} target="dk_cgdist">
                            <small>relative distance with respect to least-distance route</small>
                          </UncontrolledTooltip>
                        </CardTitle>
                        <span className="h3 font-weight-bold mb-0">{cgdist}</span>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              // EEOI 
              <Col lg="3" xs="6" xl>
                <Card className="card-stats mb-3 mb-xl-0 ml-xl--2 mr-xl--2">
                  <CardBody className="pt-2 pb-2 pl-0 pr-0 text-center">
                    <Row>
                      <div className="col">
                        <CardTitle
                         //  tag="h5"
                          id="dk_eeoi"
                          style={{fontSize:12, fontWeight:"600"}}
                          className=" text-muted mb-0">
                          Duration Savings [%]
                          <UncontrolledTooltip delay={0} target="dk_eeoi">
                            <small>relative duration with respect to least-distance route</small>
                          </UncontrolledTooltip>
                        </CardTitle>
                        <span className="h3 font-weight-bold mb-0">{eeoi}</span>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              // AER 
              <Col lg="3" xs="6" xl>
                <Card className="card-stats mb-3 mb-xl-0 ml-xl--2 mr-xl--2">
                  <CardBody className="pt-2 pb-2 pl-0 pr-0 text-center ">
                    <Row>
                      <div className="col">
                        <CardTitle
                         //  tag="h5"
                          id = "dk_aer"
                          style={{fontSize:12, fontWeight:"600"}}
                          className="text-muted mb-0">
                          Avg. wave height [m]
                          <UncontrolledTooltip delay={0} target="dk_aer">
                            <small>average significant wave height along the track</small>
                          </UncontrolledTooltip>
                        </CardTitle>
                        <span className="h3 font-weight-bold mb-0">{aer}</span>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              // CO2 savings 
              <Col lg="3" xs="6" xl>
                <Card className="card-stats mb-3 mb-xl-0 ml-xl--2 mr-xl--2">
                  <CardBody className="pt-2 pb-2 pl-0 pr-0 text-center">
                    <Row>
                      <div className="col">
                        <CardTitle
                         //  tag="h5"
                          id="dk_co2savings"
                          style={{fontSize:12, fontWeight:"600"}}
                          className=" text-muted mb-0">
                          CO<sub>2</sub> savings [%]
                          <UncontrolledTooltip delay={0} target="dk_co2savings">
                            <small>carbon dioxide, relative savings</small>
                          </UncontrolledTooltip>
                        </CardTitle>
                        <span className="h3 font-weight-bold mb-0">{dCO2}</span>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              // CII savings 
              <Col lg="3" xs="6" xl>
                <Card className="card-stats mb-3 mb-xl-0 ml-xl--2">
                  <CardBody className="pt-2 pb-2 pl-0 pr-0 text-center">
                    <Row>
                      <div className="col">
                        <CardTitle
                         //  tag="h5"
                          id="dk_ciisavings"
                          style={{fontSize:12, fontWeight:"600"}}
                          className=" text-muted mb-0">
                          CII savings [%]
                          <UncontrolledTooltip delay={0} target="dk_ciisavings">
                            <small>carbon intensity indicator, relative savings</small>
                          </UncontrolledTooltip>
                        </CardTitle>
                        <span className="h3 font-weight-bold mb-0">{dCII}</span>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row> */}

          </div>
        </Container>
      </div>
    </>
  );
});

// const convertDecimal = (decimalTimeString) => {
//   var decimalTime = parseFloat(decimalTimeString);
//   decimalTime = decimalTime * 60 * 60;
//   var hours = Math.floor((decimalTime / (60 * 60)));
//   decimalTime = decimalTime - (hours * 60 * 60);
//   var minutes = Math.floor((decimalTime / 60));
//   decimalTime = decimalTime - (minutes * 60);
//   if (hours < 10) {
//     hours = "0" + hours;
//   }
//   if (minutes < 10) {
//     minutes = "0" + minutes;
//   }
//   return (hours + ":" + minutes);
// }

export default KeyMetricsDK;
